import React, { useState, useEffect } from "react";
import app from "nystem";
import { ContentTypeRender, Wrapper, UseUser } from "nystem-components";

const ViewButtonBuyButton = ({ view, model, value, path }) => {
  const { className } = model;
  const user = UseUser();

  const [state, setState] = useState("item");

  useEffect(() => {
    const handleSubmit = async ({ errors = [] } = {}) => {
      if (!errors.length) ({ errors = [] } = await view.event("validate"));

      if (errors.length) {
        setState("Correct validation errors", errors);
        return;
      }
      setState("itemSending");
      const { data, error } = await app().database[view.contentType].save({
        data: { ...value },
        view: view.format,
      });

      if (app().settings.debug)
        console.log("zzave", { data, error }, value._id);

      if (error) {
        view.event("error", error);
        return;
      }

      const saved = await view.event("save", data);
      view.setValue({ value: saved });
    };

    view.on("submit", handleSubmit);

    return () => {
      view.off("submit", handleSubmit);
    };
  }, [model, value, view]);

  useEffect(() => {
    if (user?.cardError) {
      setState("item");
      return;
    }
    if (!user?.card) return;
    if (value.status !== "active") return;

    setState("done");
  }, [user, value.status]);

  useEffect(() => {
    const setBuybuttonState = ({ state }) => {
      setState(state);
    };
    view.on("setBuybuttonState", setBuybuttonState);
    return () => {
      view.on("setBuybuttonState", setBuybuttonState);
    };
  }, [view]);

  useEffect(() => {
    if (!user?.card) return;

    app()
      .delay(0)
      .then(async () => {
        const { data } = await app().database.subscription.search({
          filter: { user: user._id, $and: [{ status: "pending" }] },
        });
        if (!data) return;
        const at = data.find((item) => {
          if (item._crdate < Date.now() - 1000 * 60 * 60) {
            app().database.subscription.save({
              data: { ...item, status: "failed" },
            });
            return false;
          }
          return true;
        });

        if (!at) return;
        await view.setValue({ value: at });
        app().database.subscription.save({
          data: { _id: at._id, status: "active" },
          first: true,
          fields: true,
        });
      });
  }, [user, view]);

  useEffect(() => {
    if (!value._id) return;
    const update = async ({ ids }) => {
      if (ids && !ids.includes(value._id)) return;

      const { data } = await app().database.subscription.get({
        id: value._id,
      });
      view.setValue({ value: data });
      if (data.status === "failed") setState("error");
      else if (data.status === "active") setState("done");
    };
    app().database.subscription.on("update", update);
    return () => {
      app().database.subscription.off("update", update);
    };
  }, [value._id, view]);

  if (!value.product) return null;

  return (
    <Wrapper
      className={className}
      onClick={state === "item" ? () => view.event("submit") : undefined}
    >
      <ContentTypeRender path={path} items={model[state]} />
    </Wrapper>
  );
};

export default ViewButtonBuyButton;
