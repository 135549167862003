import app from "nystem";
import { Icon, MessageBusView } from "nystem-components";

const MessageBusMediaPlayPause = ({ model, className, view }) => {
  const playing = MessageBusView(view, "media", "playing");
  const isPlaying = MessageBusView(view, "media", "isPlaying");

  className = model.className ? model.className.join(" ") : "";
  const icon = model.icon || "play";

  return (
    <Icon
      onClick={() => {
        view.event("media", { playing: !playing });
      }}
      icon={!playing ? icon : isPlaying ? "pause" : "spinner"}
      className={className}
      aria-label={app().t(playing ? "Pause" : "Play")}
      role="button"
    />
  );
};

export default MessageBusMediaPlayPause;
