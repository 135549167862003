/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from "react";
import {
  InputWrapper,
  ContentTypeRender,
  UseValidator,
  Button,
  Icon,
} from "nystem-components";
import app from "nystem";
import validate from "./validate";

const Mover = ({ pos, setPos, children, className = [] }) => {
  const [style, setStyles] = useState({
    opacity: "0.9",
  });
  const pointRef = useRef();
  const [moving, setMoving] = useState(false);

  useEffect(() => {
    if (!moving) return;

    const mouseMove = (event) => {
      if (event.changedTouches) {
        event.preventDefault();
        event.stopImmediatePropagation();
        [event] = event.changedTouches;
      }

      const { pageX, pageY } = event;
      setPos({
        x: parseInt(pageX - moving.x, 10),
        y: parseInt(pageY - moving.y, 10),
      });
    };

    document.addEventListener("mousemove", mouseMove);
    document.addEventListener("touchmove", mouseMove, { passive: false });
    return () => {
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("touchmove", mouseMove);
    };
  }, [moving, setPos]);

  useEffect(() => {
    if (!moving) return;
    const mouseUp = () => setMoving(false);

    document.addEventListener("mouseup", mouseUp);
    document.addEventListener("touchend", mouseUp);
    return () => {
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("touchend", mouseUp);
    };
  }, [moving, setMoving]);

  const start = (event) => {
    if (event.changedTouches) [event] = event.changedTouches;
    if (event.altKey) setPos(false);
    else setMoving({ x: event.pageX - pos.x, y: event.pageY - pos.y });
  };

  return (
    <div
      ref={pointRef}
      className={`relative w-64 h-64 overflow-hidden cursor-move ${className.join(
        " "
      )}`}
    >
      <div
        className="absolute"
        onMouseOver={() => setStyles({ ...style, opacity: "1" })}
        onMouseOut={() => setStyles({ ...style, opacity: "0.9" })}
        onMouseDown={start}
        onTouchStart={start}
        style={{
          ...style,
          left: `${pos.x}px`,
          top: `${pos.y}px`,
          width: `${pos.zoom * 255}px`,
          heigth: `${pos.zoom * 255}px`,
        }}
      >
        <div style={{ pointerEvents: "none", userSelect: "none" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

const defaultVal = { x: 0, y: 0, zoom: 1 };
const CssmaskInput = ({ value, model, setValue, view }) => {
  const [error] = UseValidator({ view, validate, value, model });
  value = value || defaultVal;
  value.zoom = value.zoom || 1;
  const { classNameInput, item } = model;
  const [id] = useState(app().uuid);
  const [isClearing, setIsClearing] = useState(false);

  useEffect(() => {
    if (!isClearing) return;
    const doClear = () => setIsClearing(false);

    const timer = setTimeout(doClear, 50);
    return () => {
      clearTimeout(timer);
    };
  }, [isClearing, setIsClearing]);

  return (
    <InputWrapper
      id={id}
      model={{
        ...model,
        classNameInput: "relative flex-grow",
      }}
      error={error}
    >
      <div style={{ minHeight: "290px" }}>
        {isClearing ? null : (
          <Mover
            className={classNameInput}
            pos={value}
            setPos={(pos) => setValue({ ...value, ...pos })}
          >
            <ContentTypeRender items={item} />
          </Mover>
        )}
      </div>
      <div className="flex items-center font-bold">
        Zoom{" "}
        <Icon
          className="w-10 mx-2 cursor-pointer"
          icon="square-minus"
          onClick={() => {
            setValue({ ...value, zoom: value.zoom - 0.1 });
            setIsClearing(true);
          }}
        />
        <Icon
          className="w-10 mr-2 cursor-pointer"
          icon="square-plus"
          onClick={() => {
            setValue({ ...value, zoom: value.zoom + 0.1 });
            setIsClearing(true);
          }}
        />
        <Button
          onClick={() => {
            setValue(defaultVal);
            setIsClearing(true);
          }}
          size="sm"
        >
          Reset
        </Button>
      </div>
    </InputWrapper>
  );
};
export default CssmaskInput;
