import { ExportStyle, ExportView } from "nystem-components";
import app from "nystem";

const getValue = (value) => (path) =>
  path ? path.split(".").reduce((val, key) => val && val[key], value) : value;

const ReferenceExport = async ({ model, view, label }) => {
  const { id, item = [], minLength, source } = model;
  if (!item.length) return ReferenceIdExport({ model, view, label });

  let value = view.getValue(id) || [];
  value = value instanceof Array ? value : [value];

  if (label) {
    if (minLength > value.length) value = new Array(minLength).fill(1);

    return value
      .map((id, index) =>
        item.map(({ text, labelClass }) => ({
          ...ExportStyle({ className: labelClass }),
          value: text + (value.length > 1 ? index + 1 : ""),
        }))
      )
      .flat();
  }

  const baseView = view;

  let result = await Promise.all(
    value.map(async (id) => {
      const { data } = await app().database[source].get({ id });

      const view = { baseView, getValue: getValue(data) };
      return Promise.all(
        item.map(async (model) => ExportView({ model, view, label }))
      );
    })
  );

  if (!result.length) result = [item.map(() => ({ value: "" }))];
  return result.flat();
};

const ReferenceIdExport = ({ model, view, label }) => {
  const { className, labelClass, id, text, separator, fallbackField } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };

  let value = view.getValue(id) || [];
  value = value instanceof Array ? value : [value];
  if (value.length === 1 && model.asInt) value = parseInt(value, 10);
  else value = value.join(separator || " ");

  if (!value && fallbackField) value = view.getValue(fallbackField);
  return { ...ExportStyle({ className }), value };
};

export default ReferenceExport;
