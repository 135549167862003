import { useEffect } from "react";
import app from "nystem";

const ViewListStatDataSum = ({ view, model }) => {
  useEffect(() => {
    const setSearch = async (search) => {
      const { sumByField } = await app().connection.emit({
        type: `statData${view.contentType}`,
        ...search,
        sumField: model.sumField,
        byField: model.byField,
      });
      if (!sumByField) {
        await view.setValue({
          path: "statData",
          value: undefined,
        });
        return;
      }

      const value = Object.entries(sumByField).map(([key, value]) => ({
        product: key,
        ...value,
        sum: parseInt(value.sum, 10),
      }));

      await view.setValue({
        path: model.countFieldTo,
        value: value.reduce((prev, { count }) => prev + count, 0),
      });
      await view.setValue({
        path: model.byFieldTo,
        value,
      });
      await view.setValue({
        path: model.sumFieldTo,
        value: value.reduce((prev, { sum }) => prev + sum, 0),
      });
    };
    view.on("setSearch", -1000, setSearch);
    view.event("setSearch");

    return () => {
      view.off("setSearch", setSearch);
    };
  }, [model, view]);

  return null;
};
export default ViewListStatDataSum;
