import { ContentTypeView } from "nystem-components";

const ReferenceViewAsContentType = ({ model, view, value }) => {
  const { contentType, className } = model;
  if (!value) return null;
  if (!(value instanceof Array)) value = [value];

  return value.map((id) => (
    <ContentTypeView
      key={id}
      className={className}
      contentType={contentType}
      format={model.view}
      id={id}
      baseView={view}
      params={view.params}
    />
  ));
};
export default ReferenceViewAsContentType;
