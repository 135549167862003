import deleteM from "./delete";
import findM from "./find";
import getM from "./get";
import initM from "./init";
import saveM from "./save";
import searchM from "./search";

export default (app) => {
  deleteM(app);
  findM(app);
  getM(app);
  initM(app);
  saveM(app);
  searchM(app);

  app.database.on(
    "init",
    ({ contentType }) => {
      const db = { dbArray: [], dbIndex: {} };

      const collection = app.addeventhandler(
        { contentType },
        [
          "delete",
          "find",
          "get",
          "init",
          "save",
          "search",
          "size",
          "update",
          "updates",
        ],
        `database ${contentType.machinename}`
      );

      collection.on("init", () => {
        const { dbArray, dbIndex } = db;
        dbArray.forEach((item) => {
          if (dbIndex[item._id])
            dbArray.splice(dbArray.indexOf(dbIndex[item._id]), 1);
          dbIndex[item._id] = item;
        });
      });

      collection.on("size", () => db.dbArray.length);
      return { collection, db, contentType };
    },
    1000
  );
};
