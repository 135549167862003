import { Wrapper } from "nystem-components";
import app from "nystem";

const ViewTextInsertValues = ({ view, model, path }) => {
  const insertVal = (val) =>
    val &&
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      if (/pathItem[0-9]/.test(p1) && view.params) return view.params[p1[8]];

      if (p1 === "_language") return app().settings.lang;
      if (p1 === "fulldomain") {
        const { domain, secure } = app().settings;
        return `http${secure ? "s" : ""}://${domain}`;
      }
      if (p1.startsWith("params."))
        return view.params[p1.replace("params.", "")];

      if (p1 === "id") return view.id;

      if (p1.indexOf("baseView.") !== 0)
        return view.getValue(p1.replace("..", path));

      p1 = p1.replace("baseView.", "");
      return view.baseView.getValue(p1.replace("..", path));
    });

  return (
    <Wrapper className={model.className}>
      {insertVal(model.valuestring) || model.fallback}
    </Wrapper>
  );
};

export default ViewTextInsertValues;
