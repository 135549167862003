export default function (app) {
  const sentItems = {};
  let translation = {};

  const saveText = () => {
    app.t = (text) => {
      if (typeof text !== "string" || text.replace(/[^a-zåäö]/gim, "") === "")
        return text || "";

      if (!translation[text] && !sentItems[text]) {
        app.delay(500).then(() => {
          const _id = text.replace(/[^a-zåäö.0-9]/gim, "_");
          app.database.translation.save({
            data: { _id, text },
            token: window.location.search,
          });
        });
        sentItems[text] = true;
      }
      return translation[text] || text;
    };
  };

  app.storage.on("setItem", -100, ({ id, value }) => {
    if (id !== "language") return;

    lang = value;
    setTranslations();
  });

  app.t = (text) => translation[text] || text;

  let { lang } = app.settings;
  const setTranslations = async () => {
    const { data } = await app.database.translation.search({
      filter: { [lang]: true },
      count: 1000,
    });

    if (!data && !app.connection.connected) {
      await app.delay(200);
      await setTranslations();
      return;
    }

    if (!data) return;
    translation = {};
    for (const item of data) translation[item.text] = item[lang];
  };

  app.on("loaded", 100, async () => {
    if (window.location.search?.startsWith("?translationsSaveToken="))
      saveText();

    /*
    if (app.settings.langSettable) {
      let { language } = app.session.user || {};

      if (!language)
        ({ value: language } = await app.storage.getItem({ id: "language" }));
      if (language) lang = language;
    }
    */
    await setTranslations();
    app.database.translation.on("update", setTranslations);
  });
};
