import React, { useEffect, useRef, useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { Wrapper } from "nystem-components";
import app from "nystem";
import "./theme.css";

const uudecode = (code) => JSON.parse(window.atob(code));
const uuencode = (obj) => window.btoa(JSON.stringify(obj));

const errorRespons = {
  Refused: "Kort inte godkänt",
  "Blocked Card": "Blockerat kort",
  "Expired Card": "Kortet har gått ut",
  "Invalid Card Number": "Ogiltigt kortnummer",
  "CVC Declined": "Ogiltigt CVC-nummer",
};

const AdyenView = ({ view, model }) => {
  const paymentContainer = useRef();
  const [errorMsg, setErrorMsg] = useState(false);
  const challengeShopperContainer = useRef();

  useEffect(() => {
    if (!paymentContainer.current) return;

    const configuration = {
      locale: app().settings.adyen.locale,
      environment: app().settings.adyen.environment,
      clientKey: app().settings.adyen.clientKey,
      storePaymentMethod: true,
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or debit card",
          amount: { value: 0, currency: "SEK" },
        },
      },
    };

    let handleMessage;
    challengeShopperContainer.current.style.display = "none";
    challengeShopperContainer.current.style.height = "400px";
    paymentContainer.current.style.display = "";

    const on3DSresponse = ({ refusalReason }) => {
      challengeShopperContainer.current.style.display = "none";
      paymentContainer.current.style.display = "none";
      if (!refusalReason) return;

      setErrorMsg(errorRespons[refusalReason] || refusalReason);
    };
    app().connection.on("3DSresponse", on3DSresponse);

    const createCheckout = async () => {
      const checkout = await AdyenCheckout(configuration);

      const card = checkout
        .create("card", {
          styles: {},
          showPayButton: false,
          onSubmit: async (state, component) => {
            if (state.isValid) {
              const { ...result } = await app().connection.emit({
                type: "adyenSaveCard",
                data: card.data,
                pathname: window.location.pathname,
              });

              if (result.action.subtype === "challenge") {
                app().event("hideActionButton");
                paymentContainer.current.style.display = "none";
                const data = uudecode(result.action.token);
                const cReqData = {
                  threeDSServerTransID: data.threeDSServerTransID,
                  acsTransID: data.acsTransID,
                  messageVersion: data.messageVersion,
                  challengeWindowSize: "05",
                  messageType: "CReq",
                };
                const form = document.createElement("form");

                form.method = "POST";
                form.action = data.acsURL;
                form.target = "challengeShopperContainer";

                const element = document.createElement("input");
                element.type = "hidden";
                element.value = uuencode(cReqData);
                element.name = "creq";

                form.appendChild(element);
                challengeShopperContainer.current.style.display = "";
                document.body.appendChild(form);
                form.submit();

                return;
              }
              if (result.action) {
                if (result.action.type === "redirect") {
                  const form = document.createElement("form");

                  form.method = "POST";
                  form.action = result.action.url;

                  Object.entries(result.action.data).forEach(([key, value]) => {
                    const element = document.createElement("input");
                    element.type = "hidden";
                    element.value = value;
                    element.name = key;
                    form.appendChild(element);
                  });

                  document.body.appendChild(form);

                  form.submit();
                  return;
                }

                app().event("hideActionButton");
                checkout
                  .createFromAction(result.action, {
                    challengeWindowSize: "05",
                  })
                  .mount(paymentContainer.current);
                return;
              }

              setErrorMsg(
                errorRespons[result.refusalReason] || result.refusalReason
              );
            } else setErrorMsg(false);
          },
          /*
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
          */
          onChange: (state) => {
            if (!state.isValid && view.baseView)
              setTimeout(() => {
                view.baseView.event("setBuybuttonState", { state: "item" });
              }, 200);
          },
        })
        .mount(paymentContainer.current);

      return card;
    };

    const checkout = createCheckout();
    const submit = async () => {
      const item = await checkout;
      await item.submit();
    };
    view.on("submit", 100, submit);
    if (view.baseView) view.baseView.on("submit", 100, submit);

    return () => {
      if (handleMessage) window.removeEventListener("message", handleMessage);
      view.off("submit", submit);
      if (view.baseView) view.baseView.off("submit", submit);
      app().connection.off("3DSresponse", on3DSresponse);
    };
  }, [view]);

  return (
    <Wrapper className={model.className} renderAs={model.renderAs}>
      <div className="payment-container">
        <div ref={paymentContainer} className="payment"></div>
        <iframe
          ref={challengeShopperContainer}
          title="challengeShopperContainer"
          name="challengeShopperContainer"
          className="challengeShopperContainer w-full"
        />
        {errorMsg && (
          <Wrapper className="text-red-500">
            <Wrapper className="font-bold pt-2 pl-1">
              {app().t("Sparade av kort misslyckades")}
            </Wrapper>
            <Wrapper className="font-bold p-1">{app().t(errorMsg)}</Wrapper>
          </Wrapper>
        )}
      </div>
    </Wrapper>
  );
};
export default AdyenView;
