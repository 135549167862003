import { useEffect } from "react";
import "@adyen/adyen-web/dist/adyen.css";
import { UseLocation } from "nystem-components";
import app from "nystem";
import "./theme.css";

const AdyenRedirect = () => {
  const { search, pathname } = UseLocation();

  useEffect(() => {
    const ref = "?redirectResult=";
    if (!search || !search.startsWith(ref)) return;
    const redirectResult = search.substring(ref.length);

    app().connection.emit({
      type: "adyenSaveCard",
      data: { details: { redirectResult } },
    });

    window.history.replaceState({}, "", pathname);
  }, [search, pathname]);

  return null;
};
export default AdyenRedirect;
