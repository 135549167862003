import React, { useEffect, useState } from "react";
import app from "nystem";
import ReactDOM from "react-dom";

const TinymcePortal = () => {
  const [portals, setPortals] = useState([]);

  useEffect(() => {
    let portals = [];

    const reactPortal = ({ id, Component, element, viewId }) => {
      portals = portals.filter(({ id: itemId }) => id !== itemId);

      if (Component) portals = [...portals, { id, Component, element, viewId }];

      setPortals(portals);
    };

    const reactPortalViewClose = ({ id }) => {
      portals = portals.filter(({ viewId }) => id !== viewId);

      setPortals(portals);
    };

    app().on(`reactPortal`, reactPortal);
    app().on(`reactPortalViewClose`, reactPortalViewClose);
    return () => {
      app().off(`reactPortal`, reactPortalViewClose);
      app().off(`reactPortalViewClose`, reactPortalViewClose);
    };
  }, []);

  if (portals.length)
    return portals.map(({ Component, element }) =>
      ReactDOM.createPortal(<Component />, element)
    );

  return null;
};

export default TinymcePortal;
