import { Link, SessionRole, Inserter, Wrapper, Icon } from "nystem-components";
import app from "nystem";

const NavbarLink = ({ children, type, icon, ...props }) => {
  const classNames =
    "px-5 py-2 hover:bg-gray-700 hover:shadow flex items-center";

  if (type)
    props = { ...props, to: `/admin/${type}/list`, match: `/admin/${type}/*` };

  return (
    <Link {...props} className={[classNames, props.className]}>
      <Icon icon={icon} className="mr-3 h-4 w-4" />
      <Wrapper className="block flex-grow">{children}</Wrapper>
    </Link>
  );
};

const PartAdmin = () => {
  return (
    <SessionRole userrole="config super">
      <Inserter
        match="/admin*"
        className="admin m-auto max-w-6xl p-1 sm:flex gridless"
      >
        <Wrapper className="-mt-6 rounded-br-lg bg-gray-800 text-gray-200 shadow sm:mt-0 sm:w-48 sm:pb-2">
          <NavbarLink
            icon="gear"
            to="/admin"
            className="mt-6 px-3 text-xl font-semibold"
          >
            {app().t("Admin")}
          </NavbarLink>
          <NavbarLink icon="user" type="user">
            {app().t("User")}
          </NavbarLink>
          <NavbarLink icon="user-group" type="subscription">
            {app().t("Subscription")}
          </NavbarLink>
          <NavbarLink icon="money-bill-wave" type="transaction">
            {app().t("Transaction")}
          </NavbarLink>
          <Wrapper className="h-6" />
          <NavbarLink icon="tv" type="program">
            {app().t("Program")}
          </NavbarLink>
          <NavbarLink icon="video" type="episode">
            {app().t("Episode")}
          </NavbarLink>
          <NavbarLink icon="user-group" type="host">
            {app().t("Hosts")}
          </NavbarLink>
          <Wrapper className="h-6" />
          <NavbarLink icon="box-archive" type="product">
            {app().t("Product")}
          </NavbarLink>
          <NavbarLink icon="money-bill-wave" type="code">
            {app().t("Codes")}
          </NavbarLink>
          <Wrapper className="h-6" />
          <NavbarLink icon="file" type="content">
            {app().t("Content")}
          </NavbarLink>
          <NavbarLink icon="envelope" type="email">
            {app().t("Email")}
          </NavbarLink>
          <NavbarLink icon="link" type="redirect">
            {app().t("Redirect")}
          </NavbarLink>
          <NavbarLink icon="language" type="translation">
            {app().t("Translation")}
          </NavbarLink>
          <NavbarLink icon="box-archive" type="archive">
            {app().t("Archive")}
          </NavbarLink>
          <SessionRole userrole="super">
            <Link
              to="/admin/translation/trigger"
              className="px-5 py-2 hover:bg-gray-700 hover:shadow flex items-center"
            >
              <Icon icon="gear" className="mr-3 h-4 w-4" />
              {app().t("Trigger event")}
            </Link>
          </SessionRole>
        </Wrapper>
        <Wrapper className="flex-grow px-8 pt-2 pb-32 max-w-3xl">
          <Inserter match="/admin" source="/user/list" />
          <Inserter match="/admin/notification*" source="/notification*" />
          <Inserter
            match="/admin/wppostspost/list"
            source="/wppostspost/trigger"
          />
          <Inserter match="/admin/archive*" source="/archive*" />
          <Inserter
            match="/admin/transactionarchive*"
            source="/transactionarchive*"
          />
          <Inserter match="/admin/giftcard*" source="/giftcard*" />
          <Inserter match="/admin/code*" source="/code*" />
          <Inserter match="/admin/content*" source="/content*" />
          <Inserter match="/admin/user*" source="/user*" />
          <Inserter match="/admin/language*" source="/language*" />
          <Inserter match="/admin/translation*" source="/translation*" />
          <Inserter match="/admin/host/*" source="/host/*" />
          <Inserter match="/admin/program/*" source="/program/*" />
          <Inserter match="/admin/episode/*" source="/episode/*" />
          <Inserter match="/admin/subscription/*" source="/subscription/*" />
          <Inserter match="/admin/product/*" source="/product/*" />
          <Inserter match="/admin/transaction/*" source="/transaction/*" />
          <Inserter match="/admin/email/*" source="/email/*" />
          <Inserter match="/admin/menu/*" source="/menu/*" />
          <Inserter match="/admin/theme/*" source="/theme/*" />
          <Inserter match="/admin/redirect/*" source="/redirect/*" />
        </Wrapper>
      </Inserter>
    </SessionRole>
  );
};

export default PartAdmin;
