import React, { useEffect, useState } from "react";
import { ContentTypeRender, Wrapper } from "nystem-components";
import app from "nystem";

const ReferenceClassWrapperBy = ({ model, path = "", value }) => {
  const { className, item, title, outside, renderAs, eyebrow, footer } = model;
  const [data, setData] = useState();

  useEffect(() => {
    const update = async () => {
      const { data } = await app().database[model.source].get({ id: value });
      setData(data);
    };

    app().database[model.source].on("login", -10, update);
    update();

    return () => {
      app().database[model.source].off("login", update);
    };
  }, [model.source, value]);

  if (!data) return null;

  const insertVal = (val, from) =>
    val &&
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      const parts = p1.split(".");
      if (p1 === "from") return from;
      return parts[0] === "view" ? value[parts[1]] : data[p1];
    });

  return (
    <Wrapper
      renderAs={renderAs}
      className={(className, insertVal(data.wrapperclass))}
    >
      <ContentTypeRender
        path={path.replace(model.id, "")}
        items={outside || []}
      />
      <Wrapper className={(className, insertVal(data.textboxclass))}>
        <Wrapper className={insertVal(data.eyebrowclass)}>
          <ContentTypeRender
            path={path.replace(model.id, "")}
            items={eyebrow || []}
          />
        </Wrapper>
        <Wrapper className={insertVal(data.titleclass)}>
          <ContentTypeRender
            path={path.replace(model.id, "")}
            items={title || []}
          />
        </Wrapper>
        <Wrapper className={insertVal(data.bodyclass)}>
          <ContentTypeRender
            path={path.replace(model.id, "")}
            items={item || []}
          />
        </Wrapper>
        <Wrapper className={insertVal(data.footer)}>
          <ContentTypeRender
            path={path.replace(model.id, "")}
            items={footer || []}
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default ReferenceClassWrapperBy;
