import { useEffect } from "react";
import app from "nystem";

const ViewButtonCheckLoginToken = () => {
  useEffect(() => {
    let count = 0;
    let timer;

    const check = async () => {
      const { value } = await app().storage.getItem({ id: `loginTokenId` });
      if (!value) return;
      clearTimeout(timer);
      timer = setTimeout(check, 1000);

      if (count > 60 * 60) return;
      count++;

      if (!app().connection.connected) return;

      const query = await app().connection.emit({
        type: "loginToken",
        stage: "login",
        loginTokenId: value,
        pathname: window.location.pathname,
      });
      if (query.done) await app().storage.removeItem({ id: `loginTokenId` });
    };

    app().storage.on("setItem", ({ id }) => {
      if (id === `loginTokenId`) timer = setTimeout(check, 1000);
    });
    setTimeout(check, 10);

    return () => {
      count = 60 * 60 + 1;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default ViewButtonCheckLoginToken;
