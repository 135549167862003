import React, { useRef, useEffect, useState } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const MediaUploadLog = ({ model }) => {
  const [log, setLog] = useState("");
  const [count, setCount] = useState(0);
  const [done, setDone] = useState(0);
  const atCount = useRef();
  atCount.current = count;
  const atDone = useRef();
  atDone.current = done;

  useEffect(() => {
    const fileUploadLog = ({ log: add }) => {
      setLog(`${log}\n${add}`);
    };

    app().on("fileUploadLog", fileUploadLog);
    return () => {
      app().off("fileUploadLog", fileUploadLog);
    };
  }, [log]);

  useEffect(() => {
    const filePreUpload = () => {
      atCount.current += 1;
      setCount(atCount.current);
    };

    app().on("fileUpload", 10000, filePreUpload);
    return () => {
      app().off("fileUpload", filePreUpload);
    };
  }, []);

  useEffect(() => {
    const filePostUpload = ({ name }) => {
      atDone.current += 1;

      setDone(atDone.current);
      if (name) setLog(`${log}\n${name} uploaded`);
    };

    app().on("fileUpload", -10000, filePostUpload);
    return () => {
      app().off("fileUpload", filePostUpload);
    };
  }, [log]);

  useEffect(() => {
    if (count > done) return;

    const timer = setTimeout(() => {
      setCount(0);
      setDone(0);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [count, done]);

  if (!log) return null;

  return (
    <>
      <Wrapper className={model.classNameLabel}>
        {model.text} {count !== 0 && `${Math.round((100 * done) / count)} %`}
      </Wrapper>
      <Wrapper
        className={[
          model.className,
          count > done && model.classNameActive,
          count === done && log && model.classNameDone,
        ]}
      >
        {log.split(/\n/g).map((item, index) => (
          <Wrapper key={index}>
            {item}
            <br />
          </Wrapper>
        ))}
      </Wrapper>
    </>
  );
};
export default MediaUploadLog;
