import { useState } from "react";
import { Wrapper, Audio } from "nystem-components";

const TextMediaURLPlayer = ({ model, view, value }) => {
  const [playing, setPlaying] = useState();
  return (
    <Wrapper className={model.className}>
      <Audio
        className="w-full"
        preload={model.preload}
        controls={model.controls}
        src={value}
        type="audio/mpeg"
        onChange={(settings) => {
          if (model.durationField)
            view.setValue({
              path: model.durationField,
              value: settings.duration,
            });
          if (settings.playing !== undefined) setPlaying(settings.playing);
        }}
        playing={playing}
      />
    </Wrapper>
  );
};

export default TextMediaURLPlayer;
