import { useEffect, useState } from "react";
import { ContentTypeRender } from "nystem-components";
import app from "nystem";

const goto = (path, push) =>
  push
    ? window.history.pushState({}, "", path)
    : window.history.replaceState({}, "", path);

const ViewButtonLoginToken = ({ model, view, path }) => {
  const [option, setOption] = useState(false);

  useEffect(() => {
    const closeOption = () => {
      setOption(false);
    };
    view.on("closeOption", closeOption);
    return () => {
      view.on("closeOption", closeOption);
    };
  }, [view]);

  useEffect(() => {
    if (model.stage !== "verify") return;
    const { search } = window.location;

    view.value.contentType = view.contentType;
    const sendData = async () => {
      const { value } = await app().storage.getItem({ id: `loginTokenId` });
      const { redirectURL } = await app().connection.emit({
        type: "loginToken",
        search,
        stage: "verify",
        loginTokenId: value,
        pathname: window.location.pathname,
      });

      if (redirectURL === "/loginToken/option") setOption(true);
      else if (redirectURL) goto(redirectURL);
      else if (search) goto(window.location.pathname);
    };

    setTimeout(sendData, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sendNext = async () => {
      const { errors = [] } = await view.event("validate");
      if (errors.length) return;

      if (model.stage === "input") app().tempEmail = view.value.email;

      const { id, redirectURL, push } = await app().connection.emit({
        type: "loginToken",
        stage: model.stage,
        value: view.value,
        loginTokenId: await app().storage.getItem({ id: `loginTokenId` }).value,
        pathname: window.location.pathname,
      });

      if (redirectURL === "/loginToken/option") {
        setOption(true);
        return;
      }
      view.event("closeOption");

      if (redirectURL === "/loginToken/checklink")
        await app().storage.setItem({ id: `loginTokenId`, value: id });

      if (["/loginToken/remote", "/loginToken/local"].includes(redirectURL))
        await app().storage.removeItem({ id: `loginTokenId` });

      goto(redirectURL, push);
    };

    const change = async ({ value }) => {
      const { tokenLength } = app().settings;

      if (value.code.length !== tokenLength) return;

      const { id, redirectURL, push } = await app().connection.emit({
        type: "loginToken",
        stage: model.stage,
        loginTokenIdemail: app().tempEmail,
        code: value.code,
        pathname: window.location.pathname,
      });
    };

    view.on(`sendNext${model.stage}`, sendNext);
    if (model.stage === "code") view.on("change", change);

    return () => {
      if (model.stage === "code") view.off("change", change);
      view.off(`sendNext${model.stage}`, sendNext);
    };
  }, [model.stage, view]);

  if (model.stage === "login") return null;

  return (
    <ContentTypeRender path={path} items={option ? model.option : model.item} />
  );
};
export default ViewButtonLoginToken;
