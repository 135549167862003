import * as components from "nystem-components";
import app from "nystem";

const ExportView = ({ model, view, label }) => {
  const { className, labelClass, id, text, extra = [] } = model;
  const type = model.fieldType || model.type;
  model = extra.reduce((res, [key, val]) => ({ ...res, [key]: val }), model);

  const { capFirst } = app();
  let { format } = model;
  if (type !== "export" && ["view", "list", "input"].includes(format))
    format = "export";

  const componentName = `${capFirst(type)}${capFirst(format || "export")}`;

  if (components[componentName])
    return components[componentName]({ model, view, label });

  if (label)
    return {
      ...components.ExportStyle({ className: labelClass }),
      value: text,
    };
  return { ...components.ExportStyle({ className }), value: view.getValue(id) };
};

export default ExportView;
