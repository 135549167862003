import { useEffect } from "react";

const DatabaseFilterByPublishDate = ({ model, view }) => {
  useEffect(() => {
    const now = Date.now();
    const onSearch = (search) => ({
      ...search,
      data:
        search.data &&
        search.data.filter(({ publishDate, unPublishDate }) => {
          if (publishDate && publishDate > now) return false;
          if (unPublishDate && unPublishDate < now) return false;
          return true;
        }),
    });

    view.on("search", -400, onSearch);
    return () => {
      view.off("search", onSearch);
    };
  }, [model.fields, view]);

  return null;
};
export default DatabaseFilterByPublishDate;
