import {
  Wrapper,
  ContentTypeRender,
  UseUser,
  ContentTypeView,
} from "nystem-components";

const SessionPodcaster = ({ model, children, path, ...rest }) => {
  const { className, view, contentType } = model || rest;
  const user = UseUser();

  const { role, editor } = user || {};
  if (!["user", "usertype2"].includes(role) || !(editor instanceof Array))
    return null;

  if (children) return <Wrapper className={className}>{children}</Wrapper>;

  if (contentType)
    return (
      <ContentTypeView
        className={className}
        contentType={contentType}
        format={view}
        value={user}
        baseView={rest.view}
        params={rest.view.params}
      />
    );

  return (
    <Wrapper className={className}>
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};

export default SessionPodcaster;
