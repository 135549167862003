import React, { useEffect, useState } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const addHrefs = (text) =>
  text.replace(
    /<H([0-5])([^>]*)>(.*?)<\/H\1>/gim,
    (all, tag, attr, html, pos) =>
      `<H${tag}${attr} id="refid${pos}">${html}</H${tag}>`
  );
const addSpan = (text) =>
  text.replace(
    /<([a-z0-9]+)([^>]*)>(.*?)<\/\1>/gim,
    (all, tag, attr, html) => `<${tag}${attr}><span>${html}</span></${tag}>`
  );

const TinymceSpanview = ({ model, value }) => {
  const [id] = useState(app().uuid());

  useEffect(
    () => () => {
      app().event(`reactPortalViewClose`, { id });
    },
    [id]
  );

  if (!value) return null;
  if (!value.includes("<p")) value = `<p><span>${value}</span></p>`;

  return (
    <Wrapper>
      <Wrapper
        id={id}
        className={["tinymceview", model.className]}
        dangerouslySetInnerHTML={{
          __html: addHrefs(
            addSpan(
              `${value}`.replace(/<p><br data-mce-bogus="1"><\/p>/gim, "")
            )
          ),
        }}
      />
      <Wrapper className="clear-both" />
    </Wrapper>
  );
};
export default TinymceSpanview;
