import { Wrapper, ContentTypeRender } from "nystem-components";

const StyleDetails = ({ model, path }) => (
  <Wrapper renderAs="details" className={model.className}>
    <Wrapper renderAs="summary">
      <ContentTypeRender path={path} items={model.header} />
    </Wrapper>
    <ContentTypeRender path={path} items={model.body} />
  </Wrapper>
);

export default StyleDetails;
