import {
  ContentTypeRender,
  RouterUseQueryStore,
  Wrapper,
} from "nystem-components";

const ViewButtonParams = ({ model, path }) => {
  const { className, item, itemOn } = model;
  const [value, setValue, ref] = RouterUseQueryStore(model.saveId);

  return (
    <Wrapper className={className} ref={ref}>
      <Wrapper onClick={() => setValue(!value)}>
        <ContentTypeRender
          path={path}
          items={item.map((item) => ({
            ...item,
            className: [...(item.className || []), !value && "active"],
          }))}
        />
      </Wrapper>
      <Wrapper onClick={() => setValue(!value)}>
        <ContentTypeRender
          path={path}
          items={itemOn.map((item) => ({
            ...item,
            className: [...(item.className || []), value && "active"],
          }))}
        />
      </Wrapper>
    </Wrapper>
  );
};

export default ViewButtonParams;
