import { ContentTypeView, Wrapper } from "nystem-components";

const ReferenceCarousel = ({ model, view, value = [], path }) => {
  value = value instanceof Array ? value : [value];
  const { renderFormat, className, source, itemClassName } = model;

  const option = (item, index) => (
    <ContentTypeView
      key={item}
      contentType={source}
      baseView={view}
      format={renderFormat || "view"}
      id={item}
      params={view.params}
      className={[itemClassName, model.itemCountClass + (index + 1)]}
      onReference={(item) => view.event(item.event, { ...item, model, path })}
    />
  );

  return (
    <Wrapper className={[className, model.countClass + value.length]}>
      {value.map(option)}
    </Wrapper>
  );
};
export default ReferenceCarousel;
