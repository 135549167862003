import { Audio, MessageBusView } from "nystem-components";

const MessageBusMp3Player = ({ model, view }) => {
  const currentTime = MessageBusView(view, "media", "currentTime");
  const playing = MessageBusView(view, "media", "playing");
  const from = MessageBusView(view, "media", "from") || 0;
  const ext = view.value?.media?.ext || view.value?.mediaUrl;

  return (
    <Audio
      key={`${view.value._id}/${view.token}`}
      preload="metadata"
      controls={model.controls}
      src={`/getepisodemedia/${view.value._id}/${view.token}.${ext}`}
      playing={playing}
      currentTime={currentTime + from}
      updateInterval={model.updateInterval}
      onChange={(settings) => {
        if (settings.currentTime) settings.currentTime -= from;
        view.event("media", settings);
      }}
    />
  );
};

export default MessageBusMp3Player;
