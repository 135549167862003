import { useEffect } from "react";
import app from "nystem";
import { UseUser } from "nystem-components";

// https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API
const MediaSessionHandler = ({ view }) => {
  useEffect(() => {
    if (!("mediaSession" in navigator)) return;

    let metadata = false;
    let episodeId = false;
    const setMedia = async ({ episode: eId }) => {
      if (!eId || episodeId === eId) return;
      episodeId = eId;

      const { data: episode } = await app().database.episode.get({ id: eId });

      const { data } = await app().database.program.get({
        id: episode.program,
      });
      const id = episode.image?.id || data.image?.id;

      const sizes = [
        "96x96",
        "128x128",
        "192x192",
        "256x256",
        "384x384",
        "512x512",
      ];
      const artwork = sizes.map((sizes) => ({
        src: `/image/${sizes}/${id}.jpeg`,
        sizes,
        type: "image/jpeg",
      }));

      metadata = {
        title: episode.name,
        artist: data.name,
        album: "Under produktion",
        artwork,
      };
    };
    view.on("media", -1000, setMedia);

    const setSession = () => {
      if (metadata)
        navigator.mediaSession.metadata = new window.MediaMetadata(metadata);

      // console.log("dooon", navigator.mediaSession.metadata);
    };
    app().on("media", -1000, setSession);

    const play = () => {
      console.log("play");
      view.event("media", { playing: true });
      navigator.mediaSession.playbackState = "playing";
    };
    const pause = () => {
      console.log("pause");
      view.event("media", { playing: false, isPlaying: false });
      navigator.mediaSession.playbackState = "paused";
    };
    const stop = () => {
      view.event("media", { playing: false, isPlaying: false, currentTime: 0 });
    };
    const seekbackward = () => {
      const audio = document.querySelector("audio");
      view.event("media", { currentTime: audio.currentTime - 10 });
    };
    const seekforward = () => {
      const audio = document.querySelector("audio");
      view.event("media", { currentTime: audio.currentTime + 10 });
    };
    const seekto = (e) => {
      console.log(e);
      // view.event("media", { currentTime: audio.currentTime + 10 });
    };

    navigator.mediaSession.setActionHandler("play", play);
    navigator.mediaSession.setActionHandler("pause", pause);
    navigator.mediaSession.setActionHandler("stop", stop);
    navigator.mediaSession.setActionHandler("seekbackward", seekbackward);
    navigator.mediaSession.setActionHandler("seekforward", seekforward);
    navigator.mediaSession.setActionHandler("seekto", seekto);

    return () => {
      navigator.mediaSession.setActionHandler("play", null);
      navigator.mediaSession.setActionHandler("pause", null);
      navigator.mediaSession.setActionHandler("stop", null);
      navigator.mediaSession.setActionHandler("seekbackward", null);
      navigator.mediaSession.setActionHandler("seekforward", null);
      navigator.mediaSession.setActionHandler("seekto", null);
    };
  }, [view]);

  return null;
};

const ViewButtonUserPlayer = ({ view }) => {
  const user = UseUser();

  useEffect(() => {
    let playerData = {};
    let media = {};

    const setUserEv = async () => {
      if (!user) return;
      const { data } = await app().database.player.get({ id: user._id });

      playerData = data;
      if (!data) return;

      if (playerData.episode && playerData.episode !== media.episode) {
        const { data } = await app().database.episode.get({
          id: playerData.episode,
        });

        if (!data) return;

        if (data.mediaUrl && !data.mediaUrl?.match(/\.(mp3|m4a)/i)) {
          const mediaId = await (
            await fetch(`/getepisodemedia/${data._id}/${user?.token}.html`)
          ).text();
          view.mediaId = mediaId;
        } else view.token = user.token;

        media = await view.event("media", {
          currentTime: 0,
          ...playerData,
          playing: false,
          isPlaying: false,
        });
        await view.setValue({ value: data });
      }
    };

    setUserEv();

    let timer = false;
    let lastCurrent = 0;

    const updateUser = () => {
      if (!user?._id) return;

      if (Math.abs(lastCurrent - media.currentTime) > 10) {
        app().database.player.save({
          fields: true,
          data: {
            _id: user._id,
            ...media,
            to: undefined,
            from: undefined,
            isPlaying: undefined,
          },
        });

        lastCurrent = media.currentTime;
      }
    };

    const onMedia = (value) => {
      if (!value) return;

      if (media.playing !== value.playing) {
        if (value.playing) timer = setInterval(updateUser, 1000);
        else clearInterval(timer);
      }
      media = value;
    };
    view.on("media", -1000, onMedia);

    const setPlay = async ({ value }) => {
      if (value.mediaUrl && !value.mediaUrl?.match(/\.(mp3|m4a)/i)) {
        const mediaId = await (
          await fetch(`/getepisodemedia/${value._id}/${user?.token}.html`)
        ).text();
        view.mediaId = mediaId;
      } else view.token = user?.token;

      await view.setValue({ value: {} });
      await view.event("media", { clearData: true });
      await view.setValue({ value });
      setTimeout(async () => {
        media = await view.event("media", {
          playing: true,
          episode: value._id,
          currentTime: 0,
          duration: value.duration,
        });
        if (user?._id)
          app().database.player.save({
            data: {
              _id: user._id,
              ...media,
              to: undefined,
              from: undefined,
              isPlaying: undefined,
            },
          });
      }, 0);
    };
    app().on("add2Player", -1000, setPlay);

    return () => {
      view.off("media", onMedia);
    };
  }, [user, view]);

  useEffect(() => {
    let value = {};

    const fetch = (inVal) => {
      if (inVal.clearData) return {};
      value = { ...value, ...inVal };
      return value;
    };
    const update = (inVal) => {
      value = inVal;
    };

    view.on("media", 100, fetch);
    view.on("media", -100, update);
    return () => {
      view.off("media", fetch);
      view.off("media", update);
    };
  }, [view]);

  // return null;
  return <MediaSessionHandler view={view} />;
};

export default ViewButtonUserPlayer;
