import React, { useEffect } from "react";
import app from "nystem";

import { Wrapper, InputWrapper } from "nystem-components";

const sortFuncText = (key, reverse) => {
  const up = reverse ? 1 : -1;
  const down = reverse ? -1 : 1;

  return (a, b) => {
    let x = a[key];
    let y = b[key];
    if (x === undefined) return y === undefined ? 0 : up;
    if (y === undefined) return down;
    x = x.toString().toLowerCase();
    y = y.toString().toLowerCase();

    return x < y ? up : x > y ? down : 0;
  };
};

const MediaUploader = ({ model, view }) => {
  useEffect(() => {
    const media = {};

    const fileUpload = (q) => {
      if (!media[q.mediaId]) media[q.mediaId] = { count: 0, files: [] };

      if (!q.modelId) {
        q.modelId = model.id;
        q.contentType = view.contentType;
        q.params = [
          model.secure && "secure",
          ...model.addToPath.split("/"),
        ].filter((item) => item);
      }
      media[q.mediaId].count++;
    };
    app().on("fileUpload", 100980, fileUpload);
    const fileUploadDone = ({ name, mediaId }) => {
      if (!name) fileUploadProgress({ status: "init", mediaId });
    };
    app().on("fileUpload", -100980, fileUploadDone);

    const fileUploadProgress = ({ status, mediaId, ...params }) => {
      if (status !== "init") return;

      const { files } = media[mediaId];
      if (Object.keys(params).length) files.push(params);
      media[mediaId].count--;

      if (media[mediaId].count) return;
      files.sort(sortFuncText("name"));

      console.log("Media count", files.length, media[mediaId].count);
      app().database[view.contentType].save({
        data: {
          ...view.value,
          _id: undefined,
          [model.id]: files,
          name:
            files[0].folderName ||
            files[0].name.replace(/(\.[a-z0-9]*)*$/gim, ""),
        },
      });
      delete media[mediaId];
    };
    app().on("fileUploadProgress", fileUploadProgress);

    return () => {
      app().off("fileUpload", fileUpload);
      app().off("fileUploadProgress", fileUploadProgress);
    };
  }, [model, view]);

  const accept =
    model.accept ||
    (["image", "audio"].includes(model.filetype)
      ? `${model.filetype}/*`
      : undefined);

  return (
    <InputWrapper model={{ ...model }}>
      <Wrapper>
        <label className="justify-center my-1 w-96 h-20 mb-4 flex items-center px-2 py-2 bg-white text-blue rounded-lg shadow tracking-wide border border-blue cursor-pointer hover:bg-blue-500 hover:text-white">
          <svg
            className="w-8 h-8 mr-2 ml-3"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <span className="text-base leading-normal ml-1 mr-6">
            {model.limit !== 1
              ? "Drop files or click to select files"
              : "select file"}
          </span>
          <input
            multiple={model.limit !== 1}
            className="hidden"
            placeholder={app().t(model.text)}
            maxLength={model.length}
            onChange={(e) => {
              for (const pos in e.currentTarget.files)
                if (!isNaN(parseInt(pos, 10)))
                  app().event("fileUpload", {
                    file: e.currentTarget.files[pos],
                    mediaId: app().uuid(),
                    params: [
                      model.secure && "secure",
                      ...model.addToPath.split("/"),
                    ].filter((item) => item),
                    contentType: view.contentType,
                    modelId: model.id,
                    id: app().uuid(),
                    count: e.currentTarget.files.length,
                  });
            }}
            type="file"
            accept={accept}
          />
        </label>
      </Wrapper>
    </InputWrapper>
  );
};

export default MediaUploader;
