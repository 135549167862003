import { Link, Wrapper } from "nystem-components";

const UrlimageLink = ({ model, view, value }) => {
  const className = model.className ? model.className.join(" ") : "";

  const id = view.value ? `/${view.value._id}` : "";
  const format = model.toFormat ? model.toFormat : "input";
  const { params } = view;
  const addToPath =
    !params || params.length === 1 || view.contentType === params[0]
      ? ""
      : `/${params[0]}`;
  if (model.renderAs)
    return (
      <Wrapper renderAs={model.renderAs} className={className}>
        <Link to={`${addToPath}/${view.contentType}/${format}${id}`}>
          {value || model.fallback}
        </Link>
      </Wrapper>
    );

  return (
    <Link
      className={className}
      to={`${addToPath}/${view.contentType}/${format}${id}`}
    >
      {value || model.fallback}
    </Link>
  );
};
export default UrlimageLink;
