import { Wrapper, ContentTypeRender } from "nystem-components";

const StyleMatchValues = ({ model, path, view, value }) => {
  const val = [...(value[model.field] || []), ...(value[model.field2] || [])];
  const baseVal = view.baseView.value[model.baseField];
  const same = val.includes(baseVal);

  return (
    <Wrapper
      className={[model.className, same && model.match, !same && model.nomatch]}
    >
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};

export default StyleMatchValues;
