import app from "nystem";
import { Input, MessageBusView } from "nystem-components";

const MessageBusMediaSlider = ({ model, className, view }) => {
  const currentTime = MessageBusView(view, "media", "currentTime");
  const duration = MessageBusView(view, "media", "duration");

  className = model.className ? model.className.join(" ") : "";

  return (
    <Input
      placeholder={app().t(model.text)}
      className={className}
      value={parseFloat(currentTime || 0)}
      onChange={(currentTime) => {
        view.event("media", { currentTime: parseFloat(currentTime) });
      }}
      type="range"
      min={0}
      max={duration || 0}
      disabled={!duration}
      aria-label={app().t("Track position")}
    />
  );
};

export default MessageBusMediaSlider;
