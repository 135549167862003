import { useRef } from "react";
import { Wrapper, ContentTypeRender, UseUser } from "nystem-components";
import app from "nystem";

const TextApplink = ({ value, view, model, path }) => {
  const { className, item } = model;
  const ref = useRef();

  const doCopyVal = (value) => {
    const { current } = ref;
    current.value = value;
    current.style.display = "block";
    current.select();
    try {
      console.log("copy text");
      document.execCommand("copy");
      current.blur();
      current.style.display = "none";
    } catch (err) {
      console.log("please press Ctrl/Cmd+C to copy");
    }
  };

  const user = UseUser();
  let url =
    app().settings.domain +
    view.baseView.value?.fetchXML.replace(
      /https?:\/\/([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/gim,
      ""
    );
  if (url[url.length - 1] === "/") url = url.substring(0, url.length - 1);

  let baseUrl = user ? `${url}/${user.token}` : url;
  if (model.base64) baseUrl = window.btoa(`https://${baseUrl}`);

  url = value ? value.replace("{link}", baseUrl) : `https://${baseUrl}`;

  return (
    <>
      <textarea
        className="bg-black"
        ref={ref}
        style={{
          display: "none",
          position: "absolute",
        }}
        readOnly={true}
      />
      <Wrapper
        className={className}
        renderAs="a"
        target="_blank"
        href={url}
        onClick={(e) => {
          if (!model.copyVal) return;
          e.preventDefault();
          doCopyVal(url);
        }}
      >
        <ContentTypeRender
          path={path.replace(new RegExp(`.${model.id}$`, "i"), "")}
          items={item}
        />
      </Wrapper>
    </>
  );
};
export default TextApplink;
