import { TextView } from "nystem-components";

const by3 = (number, maximumFractionDigits = 0) => {
  return new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits,
  })
    .format(number)
    .replace("kr", "");
};

const IntMoney = ({ value, ...props }) => (
  <TextView
    {...props}
    value={
      value === 0 || value === undefined
        ? by3(0)
        : by3(value, props.model.maximumFractionDigits)
    }
  />
);
export default IntMoney;
