export default function (app) {
  const url2vanity = {};
  const vanity2url = {};

  app.getUrl2Vanity = (url) => url2vanity[url] || url;
  app.getVanity2Url = (url) => vanity2url[url] || url;

  const init = async ({ contentType, format }) => {
    const setUrls = async () => {
      const { data } = await app.database[contentType].search({});
      if (!data) return;

      data.forEach(({ _id, vanityUrl }) => {
        if (!vanityUrl) return;
        if (vanityUrl[0] !== "/") vanityUrl = `/${vanityUrl}`;

        url2vanity[`/${contentType}/${format}/${_id}`] = vanityUrl;
        vanity2url[vanityUrl] = `/${contentType}/${format}/${_id}`;
      });

      app.event("vanityUrlLoad");
    };

    app.database[contentType].on("update", setUrls);
    app.on(["login", "logout"], setUrls);
    await setUrls();
  };

  if (app.settings.vanityUrls)
    app.on("init", -100, async () => {
      window.document.title = app.settings.name;
      await Promise.all(app.settings.vanityUrls.map(init));
    });
};
