import React, { useContext, useEffect, useRef } from "react";
import app from "nystem";
import {
  Wrapper,
  ContentTypeRender,
  Icon,
  PanelContext,
} from "nystem-components";

const addClick = (doFunction) => ({
  onClick: doFunction,
  role: "button",
  tabIndex: "0",
});

const BootstrapPanelToggleByEvent = ({ model, path, children }) => {
  const { className, item } = model;
  const { toggleExpand, expanded } = useContext(PanelContext);
  const at = useRef();
  const exp = useRef();
  at.current = expanded;
  exp.current = toggleExpand;

  useEffect(() => {
    const checkActive = ({ event }) => {
      if (event === "compact" && at.current) exp.current();
      if (event === "expanded" && !at.current) exp.current();
    };

    app().on(model.event, checkActive);
    return () => {
      app().off(model.event, checkActive);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className={className} {...addClick(toggleExpand)}>
      {model.icon && (
        <Icon
          icon={expanded ? "chevron-down" : "chevron-right"}
          className={model.iconClassName || "pointer h-6 w-6 p-1 pl-0"}
        />
      )}
      {children || <ContentTypeRender path={path} items={item} />}
    </Wrapper>
  );
};
export default BootstrapPanelToggleByEvent;
