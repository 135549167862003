import { useEffect } from "react";
import app from "nystem";

const ReferenceAdd2Search = ({ view, model }) => {
  useEffect(() => {
    const { field, searchField } = model;

    const onSearch = async (search) => {
      const $and = search.filter?.$and;
      const val = $and?.find((item) => item.$all)?.$all;
      if (!val) return;

      const { data } = await app().database[model.source].search({
        filter: { [searchField || "$all"]: val },
        count: 2000,
      });

      if (!data) return search;

      return {
        ...search,
        filter: {
          ...search.filter,
          $and: $and.map((item) =>
            item.$all
              ? {
                  ...item,
                  [field]: data.map((item) => item._id).join("|"),
                }
              : item
          ),
        },
      };
    };

    view.on("setSearch", -30, onSearch);
    if (!model.noInitialSearch) view.event("setSearch");
    return () => {
      view.off("setSearch", onSearch);
      view.event("setSearch");
    };
  }, [view, model]);

  return null;
};

export default ReferenceAdd2Search;
