import React, { useEffect, useRef } from "react";
import { Wrapper, Audio } from "nystem-components";

const testUrl = {
  youtube:
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  vimeo:
    /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/,
};

const AddCover = ({ children, addCover }) =>
  !addCover ? (
    children
  ) : (
    <Wrapper className="relative h-full w-full">
      <Wrapper className="absolute inset-0" />
      {children}
    </Wrapper>
  );

const YoutubeVideo = ({ model, view, id, path }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    const post = (msg) =>
      ref.current.contentWindow.postMessage(JSON.stringify(msg), "*");

    const valuePath = view.getValuePath(path, model.id);
    let val = view.getValue(valuePath);
    let setCurrentTimeDone = false;

    const update = ({ id }) => {
      if (id !== valuePath) return;

      const value = view.getValue(valuePath) || {};

      if (val) {
        if (value.playing && !val.playing) {
          post({ event: "command", func: "playVideo" });
        } else if (!value.playing && val.playing)
          post({ event: "command", func: "pauseVideo" });
      }

      if (
        value.currentTime &&
        (!setCurrentTimeDone ||
          Math.abs(val.currentTime - value.currentTime) > 3)
      ) {
        setCurrentTimeDone = true;
        post({
          event: "command",
          func: "seekTo",
          args: [val.currentTime, true],
        });
      }
      val = value;
    };

    update({ id: valuePath });
    view.on("change", -1000, update);

    function onMessageReceived(event) {
      console.log(event);
      if (!/^https?:\/\/www.youtube.com/.test(event.origin)) return false;
      const data = JSON.parse(event.data);
      console.log(data);

      if (data.method === "addEventListener" && data.value === "playProgress") {
        clearInterval(playProgress);
        post({ method: "addEventListener", value: "finish" });
      }

      if (data.event === "infoDelivery") {
        const { currentTime } = data.info;

        view.setValue({
          path: valuePath,
          value: { ...val, currentTime },
        });
      }
      if (data.event === "initialDelivery") {
        const { duration } = data.info;

        view.setValue({
          path: valuePath,
          value: { ...val, duration },
        });
      }
    }

    if (window.addEventListener)
      window.addEventListener("message", onMessageReceived, false);
    else window.attachEvent("onmessage", onMessageReceived, false);

    const playProgress = setInterval(() => {
      post({ event: "listening", id: 1, channel: "widget" });
    }, 100);

    post({ event: "listening", id: 1, channel: "widget" });
    return () => {
      view.off("change", update);
      window.removeEventListener("message", onMessageReceived);
      clearInterval(playProgress);
    };
  }, [model, path, view]);

  return (
    <AddCover addCover={model.disabled}>
      <Wrapper
        ref={ref}
        src={`https://www.youtube-nocookie.com/embed/${id}?rel=0&amp;showinfo=0&html5=1&enablejsapi=1`}
        renderAs="iframe"
        className={model.className}
        allowFullScreen={true}
        frameBorder="0"
        allow="autoplay"
      />
    </AddCover>
  );
};

const VimeoVideo = ({ model, view, id, path }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    const post = (msg) =>
      ref.current.contentWindow.postMessage(JSON.stringify(msg), "*");

    const valuePath = view.getValuePath(path, model.id);
    let val = view.getValue(valuePath);
    let currentTime = false;

    const update = ({ id }) => {
      if (id !== valuePath) return;

      const value = view.getValue(valuePath) || {};

      if (val) {
        if (value.playing && !val.playing) {
          post({ method: "play" });
        } else if (!value.playing && val.playing) post({ method: "pause" });
      }

      if (!value) return;

      if (
        (value.currentTime && !currentTime) ||
        (val && Math.abs(val.currentTime - value.currentTime) > 3)
      ) {
        ({ currentTime } = val);
        post({ method: "setCurrentTime", value: val.currentTime });
      }
      val = value;
    };

    update({ id: valuePath });
    view.on("change", -1000, update);

    function onMessageReceived(event) {
      if (!/^https?:\/\/player.vimeo.com/.test(event.origin)) return false;
      const data = JSON.parse(event.data);

      if (data.method === "addEventListener" && data.value === "playProgress") {
        clearInterval(playProgress);
        post({ method: "addEventListener", value: "finish" });
      }

      if (data.event === "playProgress") {
        const { seconds, duration } = data.data;

        view.setValue({
          path: valuePath,
          value: { ...val, currentTime: seconds, duration },
        });
      }
    }

    if (window.addEventListener)
      window.addEventListener("message", onMessageReceived, false);
    else window.attachEvent("onmessage", onMessageReceived, false);

    const playProgress = setInterval(() => {
      post({ method: "addEventListener", value: "playProgress" });
    }, 100);

    return () => {
      view.off("change", update);
      window.removeEventListener("message", onMessageReceived);
      clearInterval(playProgress);
    };
  }, [model, path, view]);

  return (
    <AddCover addCover={model.disabled}>
      <Wrapper
        ref={ref}
        src={`https://player.vimeo.com/video/${id}?api=1&player_id=player1`}
        renderAs="iframe"
        className={model.className}
        allowFullScreen={true}
        frameBorder="0"
        allow="autoplay"
      />
    </AddCover>
  );
};

const MediaEmbed = (settings) => {
  const { model, value = {}, view, setValue } = settings;
  const mediaUrl = view.getValue(model.idVal); // useValue(model.idVal, view);

  if (mediaUrl.match(/\.mp3/i))
    return (
      <Audio
        key={mediaUrl}
        preload={model.preload}
        controls={model.controls}
        src={mediaUrl}
        type="audio/mpeg"
        playing={value.playing}
        currentTime={value.currentTime}
        updateInterval={model.updateInterval}
        onChange={(settings) => {
          setValue({ ...value, ...settings });
        }}
      />
    );

  let [, id] = testUrl.youtube.exec(mediaUrl) || [];
  if (id) return <YoutubeVideo key={mediaUrl} {...settings} id={id} />;

  [, id] = testUrl.vimeo.exec(mediaUrl) || [];
  if (id) return <VimeoVideo key={mediaUrl} {...settings} id={id} />;

  return null;
};

export default MediaEmbed;
