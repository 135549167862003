import React from "react";
import { Image } from "nystem-components";

class ViewImageView extends React.Component {
  render() {
    const { model } = this.props;
    const className = model.className ? model.className.join(" ") : "";
    if (model.width)
      return (
        <Image
          className={className}
          src={`/image/${model.width}x${model.height}/${model.filename}`}
        />
      );
    return <Image className={className} src={`/image/${model.filename}`} />;
  }
}
export default ViewImageView;
