import { Wrapper, ContentTypeRender } from "nystem-components";

const StylePopup = ({ model, path }) => (
  <Wrapper className={["popup-shim"]}>
    <Wrapper className={["fringe-pop", "theme--dark-on-1"]}>
      <Wrapper className={["pop-header"]}>
        <Wrapper className={["pop-title dymo"]}>
          <ContentTypeRender path={path} items={model.header || []} />
        </Wrapper>
      </Wrapper>
      <Wrapper className={["pop-header-buttons"]}>
        <ContentTypeRender path={path} items={model.buttons || []} />
      </Wrapper>
      <Wrapper className={["pop-body fringe"]}>
        <ContentTypeRender path={path} items={model.bodyoutsidepayload || []} />
        <Wrapper className={["spacer-0"]} />
        <Wrapper className={["pop-payload"]}>
          <ContentTypeRender path={path} items={model.body || []} />
        </Wrapper>
      </Wrapper>
      <Wrapper className={["pop-footer"]}>
        <Wrapper className={["pop-footer-buttons"]}>
          <ContentTypeRender path={path} items={model.footer || []} />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  </Wrapper>
);

export default StylePopup;
