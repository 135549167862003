/* eslint-disable no-continue */
import { Wrapper, ContentTypeRender } from "nystem-components";

const ConditionalMediaType = ({ value, model, path }) => {
  let show = false;

  const isAudio =
    value.mediaUrl?.match(/\.(mp3|m4a)/i) ||
    ["mp3", "m4a"].includes(value.media?.ext);

  if (model.fieldType === "video" && !isAudio) show = true;
  if (model.fieldType === "audio" && isAudio) show = true;
  if (model.fieldType === "image" && isAudio) show = true;

  if (show)
    return (
      <Wrapper className={model.className}>
        <ContentTypeRender path={path} items={model.item} />
      </Wrapper>
    );

  return null;
};

export default ConditionalMediaType;
