import React, { useContext, useState, useEffect } from "react";
import {
  DatabaseSearchContext,
  Wrapper,
  ContentTypeView,
} from "nystem-components";

const ViewListRender = ({ model, view, value, at, previous }) => {
  const { itemClassName = [] } = model;
  value = model.value || value;

  function createItem(item, index) {
    const id = ["number", "string"].includes(typeof item) ? item : item._id;

    const settings = {
      view: model,
      contentType: view.contentType,
      id,
      key: id,
      noForm: true,
      baseView: view,
      params: view.params,
      className: [
        itemClassName,
        index === at && "carousel-enter",
        index === previous && "carousel-exit",
      ],
      renderAs: item.renderAs,
      itemRenderAs: item.renderAs === "tr" && "td",
    };

    return <ContentTypeView {...settings} />;
  }

  return value.map(createItem);
};

const ViewListCarousel = ({ model, view }) => {
  const [at, setAt] = useState(0);
  const [previous, setPrevious] = useState(undefined);

  const { search } = useContext(DatabaseSearchContext);

  const value = (search && search.data) || [];

  useEffect(() => {
    if (value.length === 1) return;
    const timer = setTimeout(() => {
      setPrevious(at);
      setTimeout(() => {
        if (at !== value.length - 1) setAt(at + 1);
        else setAt(0);
      }, 500);
    }, 7000);
    return () => {
      clearTimeout(timer);
    };
  }, [at, value.length]);

  return (
    <Wrapper className={model.className}>
      <ViewListRender
        value={value}
        model={model}
        view={view}
        at={at}
        previous={previous}
      />
    </Wrapper>
  );
};
export default ViewListCarousel;
