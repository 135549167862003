import { useEffect, useState } from "react";
import { TextView } from "nystem-components";
import app from "nystem";

const FloatVat = ({ value, ...props }) => {
  const { field, show, tofixed } = props.model;
  const [products, setProducts] = useState();

  useEffect(() => {
    const view =
      props.view.contentType === "product" ? props.view.baseView : props.view;

    let product = view.getValue("product");
    product = product instanceof Array ? product : [product];
    const fetchData = async () => {
      const products = [];
      for await (const oneProduct of product) {
        const { data } = await app().database.product.get({ id: oneProduct });
        products.push(data);
      }
      setProducts(products);
    };
    fetchData();
  }, [props.view, field, show]);

  if (!products) return null;

  const isProduct = props.view.contentType === "product";
  const view = isProduct ? props.view.baseView : props.view;
  value = 0;
  const parts = view.getValue("part") || [];
  const productPrices = view.getValue("productPrices") || [];
  const priceSum = view.getValue("price");

  products.forEach((product, index) => {
    if (isProduct && props.view.id !== product._id) return;

    let days = parts[index] || "";
    const price =
      products.length === 1 ? priceSum : productPrices[index] || product.price;
    let part = 1;

    if (days) {
      if (product.recurring === "year") {
        part = (days + 335) / 365;
        days += 335;
      } else part = days / 30;
    }
    if (show === "days") value = days;

    if (show === "part" && part !== 1) value = part * 100;
    if (!part) part = 1;
    const calcPrice = Math.trunc(price * (productPrices[index] ? part : 1));

    if (show === "vat")
      value += calcPrice - calcPrice / (1 + product.vat / 100);

    if (show === "sum") value += calcPrice;
  });

  if (value) value = parseFloat(value).toFixed(tofixed).replace(".", ",");
  if (show === "part" && value) value = `(${value}%)`;
  if (show === "days" && value) value = `(${value} ${app().t("days")})`;
  return <TextView {...props} value={value === 0 ? 0 : value} />;
};
export default FloatVat;
