import React, { useState, useEffect } from "react";
import { InputWrapper, ContentTypeView } from "nystem-components";

const ReferenceSelectByOption = ({ model, view, value, setValue, path }) => {
  const { exposed } = model;
  const [option, setOption] = useState([]);

  if (!value) value = [];
  value = value instanceof Array ? value : [value];

  useEffect(() => {
    const fetchVal = (p1) => {
      if (p1 === "id") return view.id;

      if (p1.indexOf("params.") === 0)
        return view.params[p1.replace("params.", "")];

      if (p1.indexOf("baseView.") !== 0)
        return view.getValue(p1.replace("..", path));

      p1 = p1.replace("baseView.", "");
      return view.baseView.getValue(p1.replace("..", path));
    };

    setOption(fetchVal(model.optionFrom) || []);
  }, [model.optionFrom, path, view]);

  useEffect(() => {
    if (value.length || !model.autoselectifone || option.length !== 1) return;

    setValue(option[0]);
  }, [model.autoselectifone, option, setValue, value.length]);

  useEffect(() => {
    const modelId = model.id;
    const events = exposed
      ? { add: "exposedReferenceOption", remove: "exposedReferenceRemove" }
      : { add: "referenceOption", remove: "referenceRemove" };

    const add = ({ model, value: addValue, path: atPath }) => {
      if (path !== atPath || modelId !== model.id) return;

      const newVal = [...value, addValue._id];
      const addable = !model.limit || newVal.length <= model.limit;
      if (!addable) newVal.shift();
      setValue(model.limit === 1 ? newVal[0] : newVal);
    };
    const remove = ({ model, value: removeValue, path: atPath }) => {
      if (path !== atPath || modelId !== model.id) return;

      const newVal = value.filter((id) => `${id}` !== `${removeValue._id}`);
      setValue(newVal.length ? newVal : undefined);
    };
    view.on(events.add, add);
    view.on(events.remove, remove);
    return () => {
      view.off(events.add, add);
      view.off(events.remove, remove);
    };
  }, [view, setValue, value, model.id, model.limit, path, exposed]);

  return (
    <InputWrapper model={model}>
      {option.map((item, index) => (
        <ContentTypeView
          key={index}
          contentType={model.source}
          baseView={view}
          format={
            value.includes(item) ? model.renderFormat : model.renderFormatSelect
          }
          onReference={(item) => {
            view.event(item.event, { ...item, model, path });
          }}
          id={item}
        />
      ))}
    </InputWrapper>
  );
};
export default ReferenceSelectByOption;
