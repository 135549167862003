import {
  MessageBusMp3Player,
  MessageBusYoutubeVideo,
  MessageBusVimeoVideo,
} from "nystem-components";

const testUrl = {
  youtube:
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  vimeo:
    /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/,
};

const TextMediaEmbed = ({ model, value: mediaUrl, view }) => {
  if (!mediaUrl && view.value?.media?.ext)
    return (
      <MessageBusMp3Player
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  if (!mediaUrl || !mediaUrl.match) return null;

  if (mediaUrl.match(/\.(mp3|m4a)/i))
    return (
      <MessageBusMp3Player
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  let [, id] = testUrl.youtube.exec(mediaUrl) || [];
  if (id)
    return (
      <MessageBusYoutubeVideo
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  [, id] = testUrl.vimeo.exec(mediaUrl) || [];
  if (id)
    return (
      <MessageBusVimeoVideo
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  return null;
};

export default TextMediaEmbed;
