import React, { useEffect } from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const ViewLinkPodcast = ({ model, view, path, value }) => {
  const insertVal = (val) =>
    val &&
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "_language") val = app().settings.lang;
      else if (p1 === "id") val = view.id;
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        val = view.baseView.getValue(p1.replace("..", path));
      }
      if (val instanceof Array) val = val.join("|");
      return val;
    });

  const { href, className } = model;

  useEffect(() => {
    if (!value.token && value._id)
      app().database[view.contentType].save({
        fields: true,
        data: { _id: value._id, token: app().uuid() },
      });
  }, [value, view.contentType]);

  return (
    <Wrapper
      className={className}
      href={insertVal(href)}
      renderAs="a"
      target="_blank"
    >
      {app().t(model.text)}
    </Wrapper>
  );
};
export default ViewLinkPodcast;
