import React, { useEffect, useState } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const addHrefs = (text) =>
  text.replace(
    /<H([0-5])([^>]*)>(.*?)<\/H\1>/gim,
    (all, tag, attr, html, pos) =>
      `<H${tag}${attr} id="refid${pos}">${html}</H${tag}>`
  );

const TinymceView = ({ model, value, view }) => {
  const [id] = useState(app().uuid());

  useEffect(() => {
    const getView = (query) => {
      if (query.viewId !== id) return;
      return { ...query, baseView: view };
    };
    app().on(`tinymceComponent`, 100, getView);

    return () => {
      app().event(`reactPortalViewClose`, { id });
      app().off(`tinymceComponent`, getView);
    };
  }, [id, view]);

  if (!value || value.replace(/<[^>]+>/gim, "") === "") return null;

  return (
    <Wrapper>
      <Wrapper
        id={id}
        className={["tinymceview", model.className]}
        dangerouslySetInnerHTML={{ __html: addHrefs(value) }}
      />
      <Wrapper className="clear-both" />
    </Wrapper>
  );
};
export default TinymceView;
