import { Wrapper, ContentTypeRender } from "nystem-components";

const ViewButtonPrint = ({ children, model, ...rest }) => {
  const { className, item } = model || rest;

  return (
    <Wrapper className={className} onClick={() => window.print()}>
      <ContentTypeRender path={rest.path} items={item} />
    </Wrapper>
  );
};

export default ViewButtonPrint;
