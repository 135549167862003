import { useEffect } from "react";

const fisherYates = (array) => {
  array = [...array];
  let count = array.length;
  let randomnumber;
  let temp;

  while (count) {
    // eslint-disable-next-line no-bitwise
    randomnumber = (Math.random() * count--) | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp;
  }

  return array;
};

const DatabaseRandom = ({ view }) => {
  useEffect(() => {
    const onSearch = (search) => ({
      ...search,
      data: search.data && fisherYates(search.data),
    });

    view.on("search", -400, onSearch);
    return () => {
      view.off("search", onSearch);
    };
  }, [view]);

  return null;
};
export default DatabaseRandom;
