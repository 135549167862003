import { useEffect, useState, useContext } from "react";
import { Wrapper, DatabaseSearchContext } from "nystem-components";
import app from "nystem";

const ViewButtonEventSum = ({ value, model }) => {
  const [sum, setSum] = useState(false);
  const { className, renderAs } = model;
  const { search } = useContext(DatabaseSearchContext);

  useEffect(() => {
    const { event, fieldType } = model;
    if (!["total", "searchTotal"].includes(fieldType)) return;
    let haveSet = false;

    const update = async ({ value }) => {
      if (!haveSet) haveSet = true;
      return { value: parseFloat(search[fieldType]) + value };
    };

    app().on(event, fieldType === "sum" ? -10 : 0, update);

    const timer = setTimeout(() => {
      if (haveSet) return;

      haveSet = true;
      app().event(event, { value: 0 });
    }, 200);

    return () => {
      clearTimeout(timer);
      app().off(event, update);
    };
  }, [model, search]);

  useEffect(() => {
    const { event, field, fieldType } = model;
    if (!["field"].includes(fieldType)) return;

    let haveSet = false;

    const update = async ({ value: val }) => {
      if (!haveSet) haveSet = true;
      return { value: parseFloat(value[field]) + val };
    };

    app().on(event, update);

    const timer = setTimeout(() => {
      if (haveSet) return;

      haveSet = true;
      app().event(event, { value: 0 });
    }, 200);

    return () => {
      clearTimeout(timer);
      app().off(event, update);
    };
  }, [model, value]);

  useEffect(() => {
    const { event, fieldType } = model;
    if (!["sum"].includes(fieldType)) return;
    let haveSet = false;

    const update = async ({ value }) => {
      if (!haveSet) haveSet = true;
      setSum(value);
    };

    app().on(event, -10, update);

    const timer = setTimeout(() => {
      if (haveSet) return;

      haveSet = true;
      app().event(event, { value: 0 });
    }, 200);

    return () => {
      clearTimeout(timer);
      app().off(event, update);
    };
  }, [model]);

  if (value === false) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      {sum}
    </Wrapper>
  );
};

export default ViewButtonEventSum;
