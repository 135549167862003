import { Wrapper } from "nystem-components";

const ViewTextId = ({ model, view }) => (
  <Wrapper
    className={model.className}
    renderAs={model.renderAs}
    translate={model.translate}
    title={view.id}
  >
    {view.id}
  </Wrapper>
);

export default ViewTextId;
