import { Icon, MessageBusView } from "nystem-components";

const MessageBusMediaSkip = ({ model, className, view }) => {
  const currentTime = MessageBusView(view, "media", "currentTime");
  const duration = MessageBusView(view, "media", "duration");
  className = model.className ? model.className.join(" ") : "";

  const byType = {
    minus10: () => {
      let val = currentTime - 10;
      if (val < 0) val = 0;
      view.event("media", { currentTime: val });
    },
    plus10: () => {
      let val = currentTime + 10;
      if (val > duration) val = duration;
      view.event("media", { currentTime: val });
    },
  };
  const iconByType = {
    minus10: "arrow-rotate-left",
    plus10: "arrow-rotate-right",
  };

  const controlType = model.back ? "minus10" : "plus10";
  return (
    <Icon
      onClick={byType[controlType]}
      icon={iconByType[controlType]}
      className={className}
      role="button"
    />
  );
};

export default MessageBusMediaSkip;
