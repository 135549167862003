/* eslint-disable guard-for-in */
import React, { useState, useEffect } from "react";
import moment from "my-moment";
import { Wrapper } from "nystem-components";

const ExportAsJson = ({ model, view }) => {
  const [text, setText] = useState(model.text);

  const { className, renderAs } = model;

  useEffect(() => {
    let rows = false;
    let labels = [];

    const { addDate } = model;

    const exportStart = async () => {
      rows = [];
    };
    view.on("exportStart", 100, exportStart);

    const exportLabels = ({ data }) => {
      labels = data.map(({ value }) => value);
    };
    view.on("exportLabels", exportLabels);

    const exportAddRow = async ({ data }) => {
      const value = data.map(({ value }) => value);

      rows.push(
        labels.reduce((result, key, index) => {
          if (value[index]) result[key] = value[index];
          return result;
        }, {})
      );
    };
    view.on("exportAddRow", -10, exportAddRow);

    const exportFinish = async (query) => {
      if (query.aborted) return;

      const blob = new Blob([JSON.stringify(rows)], {
        type: "application/json",
      });

      let fileName = model.filename || "";
      if (addDate)
        fileName += ` ${moment()
          .format("YYYY-MM-DD HH:mm")
          .replace(":", ".")} `;
      fileName += ".json";

      return { ...query, fileName, blob };
    };
    view.on("exportFinish", 100, exportFinish);
    const exportFinishDone = () => {
      setText(model.text);
    };
    view.on("exportFinish", -1000, exportFinishDone);

    return () => {
      view.off("exportStart", exportStart);
      view.off("exportLabels", exportLabels);
      view.off("exportAddRow", exportAddRow);
      view.off("exportFinish", exportFinish);
      view.on("exportFinish", exportFinishDone);
    };
  }, [model, view]);

  return (
    <Wrapper
      onClick={(event) => {
        if (text === model.text) {
          setText(model.textProgress);
          view.event("exportStart", { event, excel: true });
        } else {
          setText(model.text);
          view.event("exportFinish", { aborted: true });
        }
      }}
      className={className}
      renderAs={renderAs}
    >
      {text}
    </Wrapper>
  );
};

export default ExportAsJson;
