import React, { useState, useEffect } from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender, UseUser } from "nystem-components";

const SessionUseraccess = ({ model, view, path }) => {
  const [visible, setVisible] = useState(undefined);
  const user = UseUser();

  useEffect(() => {
    const insertVal = (p1) => {
      if (p1 === "_language") return app().settings.lang;
      if (p1 === "id") return view.id;
      if (p1.indexOf("baseView.") !== 0)
        return view.getValue(p1.replace("..", path));
      p1 = p1.replace("baseView.", "");
      return view.baseView.getValue(p1.replace("..", path));
    };

    const updateVisibility = async () => {
      let productId = insertVal(model.productId);
      // eslint-disable-next-line prefer-destructuring
      if (productId instanceof Array) productId = productId[0];

      const { data: program } = await app().database.program.get({
        id: productId,
      });

      if (program?.type === "free" && !view.value.productoptions) {
        setVisible(!model.invert);
        return;
      }
      let access = false;

      if (user) {
        if (["usertype2", "config", "super"].includes(user.role)) access = true;
        else
          access =
            user.access &&
            (user.access.includes(productId) ||
              user.access.includes(view.value._id));
      }

      // eslint-disable-next-line no-bitwise
      setVisible(model.invert ^ access);
    };
    updateVisibility();
  }, [model, path, user, view]);

  if (visible === undefined) return null;

  if (!visible)
    return model.noaccess?.length ? (
      <Wrapper className={model.className}>
        <ContentTypeRender path={path} items={model.noaccess} />
      </Wrapper>
    ) : null;

  return (
    <Wrapper className={model.className}>
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};

export default SessionUseraccess;
