import { useEffect } from "react";
import app from "nystem";

const ViewButtonMirrorValue = ({ model, view, path }) => {
  const { transfer = [], syncTransfer = [] } = model;

  useEffect(() => {
    const insertVal = (p1) => {
      if (p1 === "id") return view.id;
      if (p1 === "_language") return app().settings.lang;

      if (p1.indexOf("params.") === 0)
        return view.params[p1.replace("params.", "")];

      if (p1.indexOf("baseView.") !== 0)
        return view.getValue(p1.replace("..", path));

      p1 = p1.replace("baseView.", "");
      return view.baseView.getValue(p1.replace("..", path));
    };

    const update = async ([from, to]) => {
      const fromVal = insertVal(from);
      const toVal = insertVal(to);
      if (toVal === fromVal) return;

      if (to.indexOf("baseView.") !== 0) {
        await view.setValue({ path: to, value: fromVal });
        return;
      }
      to = to.replace("baseView.", "");
      await view.baseView.setValue({ path: to, value: fromVal });
    };

    const doUpdates = async (fields) => {
      for (const item of fields) await update(item);
    };
    doUpdates(transfer);

    const change = ({ path }) => {
      doUpdates(
        syncTransfer.filter(([from]) => {
          if (from.indexOf("baseView.") === 0) return false;
          if (!from.startsWith(path)) return false;
          return true;
        })
      );
    };
    view.on("change", -100, change);

    const changeBaseView = ({ path }) => {
      doUpdates(
        syncTransfer.filter(([from]) => {
          if (from.indexOf("baseView.") !== 0) return false;
          if (!from.startsWith(path)) return false;
          return true;
        })
      );
    };
    view.baseView.on("change", -100, changeBaseView);

    return () => {
      view.off("change", change);
      view.baseView.off("change", changeBaseView);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ViewButtonMirrorValue;
