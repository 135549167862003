import React from "react";
import { Wrapper } from "nystem-components";
import "./css/button.css";

const types = {
  light: "button button--3d",
  primary: "bg-blue-800 hover:bg-blue-500 text-white shadow-sm active",
  secondary:
    "bg-gray-800 hover:bg-gray-700 text-gray-200 border border-gray-700 shadow-sm",
  danger: "bg-red-700 hover:bg-red-600 text-white shadow-sm",
  warning: "bg-yellow-600 hover:bg-yellow-500 text-white shadow-sm",
  success: "bg-green-600 hover:bg-green-500 text-white shadow-sm",
  info: "bg-green-600 hover:bg-green-500 text-white shadow-sm",
  error: "bg-green-600 hover:bg-green-500 text-white shadow-sm",
  default:
    "bg-gray-800 hover:bg-gray-700 text-gray-200 border border-gray-700 shadow-sm",
  primaryDisabled: "bg-blue-400 cursor-not-allowed",
  secondaryDisabled: "bg-gray-600 text-white cursor-not-allowed",
  dangerDisabled: "bg-red-400 text-white cursor-not-allowed",
  warningDisabled: "bg-yellow-300 text-white cursor-not-allowed",
  infoDisabled: "bg-green-600 hover:bg-green-500 text-white",
  errorDisabled: "bg-green-600 hover:bg-green-500 text-white",
  defaultDisabled: "bg-gray-300 text-white cursor-not-allowed",
  underproduktion: "button button--3d",
};

const sizes = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1 px-2 text-sm",
  base: "py-2 px-4 text-base",
  lg: "py-4 px-6 text-lg",
  xl: "py-4 px-8 text-xl",
  "2xl": "py-4 px-8 text-xl2",
};

const Button = ({
  onClick,
  className = [],
  type,
  size,
  disabled,
  ...props
}) => {
  className = className instanceof Array ? [...className] : [className];
  className = className.flat(Infinity);

  if (!className.includes("button")) {
    className.push(types[(type || "default") + (disabled ? "Disabled" : "")]);
    className.push(sizes[size] || sizes.base);
  } else {
    if (type === "primary") className.push("active");
    if (size !== "base") className.push(sizes[size]);
  }
  if (onClick)
    props.onClick = (e) => {
      e.preventDefault();
      onClick(e);
    };

  if (props.Component)
    return React.createElement(props.Component, {
      className,
      ...props,
    });

  return <Wrapper renderAs="button" className={className} {...props} />;
};
export default Button;
