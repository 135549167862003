import React, { useEffect, useState } from "react";
import { Wrapper, Link, UseLocation } from "nystem-components";

const addHrefs = (text, callback = (pos) => pos) =>
  text.replace(
    /<H([0-5])([^>]*)>(.*?)<\/H\1>/gim,
    (all, tag, attr, html, pos) =>
      `<H${tag}${attr} id="refid${callback(pos, tag, html)}">${html}</H${tag}>`
  );

const getScrollParent = (element, includeHidden) => {
  let { position, overflowY, overflowX, overflow } = getComputedStyle(element);
  const overflowRegex = includeHidden
    ? /(auto|scroll|hidden)/
    : /(auto|scroll)/;

  if (position === "fixed") return document.body;

  // eslint-disable-next-line no-cond-assign
  for (let parent = element; (parent = parent.parentElement); ) {
    ({ position, overflowY, overflowX, overflow } = getComputedStyle(parent));

    if (position === "absolute" && position === "static") continue;
    if (overflowRegex.test(overflow + overflowY + overflowX)) return parent;
  }

  return document.body;
};

const TinymceHeaderlist = ({ model, value, view, path }) => {
  const [active, setActive] = useState();
  const [list, setList] = useState();
  const { pathname, hash } = UseLocation();

  const insertVal = (val) =>
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "id") val = view.id;
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        val = view.baseView.getValue(p1.replace("..", path));
      }
      if (val instanceof Array) val = val.join("|");
      return val || "";
    });
  const tag = model.tag instanceof Array ? model.tag : [model.tag];
  const findTag = tag.map((tag) => tag.replace("h", ""));

  const id = insertVal(model.url).replace(model.urlremove || "", "");
  const url = `${id}#refid`;
  const at = pathname === id;

  useEffect(() => {
    if (!value) return;

    let scroller;
    const list = [];
    addHrefs(value, (pos, tag, html) =>
      list.push({
        tag,
        pos,
        html: html
          .replace(/<([A-Z][A-Z-0-9]*)[^>]*>(.*?)<\/\1>/gim, "")
          .replace(/<([A-Z][A-Z-0-9]*)[^>]*>/gim, "")
          .replace(/&nbsp;/gim, ""),
      })
    );
    setList(list);

    if (!at) {
      setActive(false);
      return;
    }

    let parentTop;
    setTimeout(() => {
      if (!list[0]) return;
      const element = document.getElementById(`refid${list[0].pos}`);
      if (!element) return;

      scroller = getScrollParent(element, true);

      parentTop = element.closest(".tinymceview").getBoundingClientRect().top;
      // scroller.scrollTop;

      scroller.addEventListener("scroll", check);
      check();
    }, 100);

    const check = () => {
      const byPos = list.map((item) => {
        const element = document.getElementById(`refid${item.pos}`);
        if (!element) return 0;
        return element.getBoundingClientRect().top;
      });

      const reducer = (prev, top, index) =>
        top > 10 + parentTop ? prev : index;
      const at = byPos.reduce(reducer, 0);

      setActive(at);
    };

    return () => {
      if (scroller) scroller.removeEventListener("scroll", check);
    };
  }, [model, path, value, view, at]);

  if (!value || !list) return null;

  return (
    <Wrapper>
      {list
        .filter(({ tag }) => findTag.includes(tag))
        .map(({ pos, html }) => (
          <Wrapper
            key={pos}
            onClick={() => {
              setTimeout(() => {
                const element = document.getElementById(`refid${pos}`);

                element.style.backgroundColor = "#f0f0f0";
                element.style.borderRadius = "0.5rem";
                setTimeout(() => {
                  element.style.backgroundColor = "";
                  element.style.borderRadius = "";
                }, 200);

                const parent = element
                  .closest(".tinymceview")
                  .getBoundingClientRect();
                const { top } = element.getBoundingClientRect();

                window.scrollTo({
                  top: top + window.scrollY - (parent.top + window.scrollY),
                  behavior: "smooth",
                });
              }, 100);
            }}
            className={[hash && (url + pos).includes(hash) && "font-bold"]}
          >
            <Link to={url + pos} className={model.className}>
              {html}
            </Link>
          </Wrapper>
        ))}
    </Wrapper>
  );
};
export default TinymceHeaderlist;
