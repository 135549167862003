import { useEffect } from "react";

const ViewButtonSetValueOnEvent = ({ view, model }) => {
  useEffect(() => {
    const setValue = async () => {
      view.setValue({ path: model.field, value: model.value });
    };
    view.on(model.event, setValue);
    return () => {
      view.off(model.event, setValue);
    };
  }, [model, view]);

  return null;
};

export default ViewButtonSetValueOnEvent;
