import { useEffect, useState } from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ViewButtonHideable = ({ view, model, path }) => {
  const [hidden, setHidden] = useState(false);
  const { className, renderAs, item } = model;

  useEffect(() => {
    const hideEvent = () => {
      setHidden(true);
    };
    const showEvent = () => {
      setHidden(false);
    };
    if (model.hideEvent) app().on(model.hideEvent, hideEvent);
    if (model.showEvent) app().on(model.showEvent, showEvent);

    return () => {
      if (model.hideEvent) app().off(model.hideEvent, hideEvent);
      if (model.showEvent) app().off(model.showEvent, showEvent);
    };
  }, [model, view]);

  return hidden ? null : (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default ViewButtonHideable;
