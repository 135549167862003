import { Inserter, Wrapper, Icon, Link } from "nystem-components";
import app from "nystem";

const PartPodcaster = () => {
  return (
    <Inserter
      match="/admin*"
      className="admin m-auto max-w-6xl p-1 sm:flex gridless"
    >
      <Wrapper className="-mt-6 rounded-br-lg bg-gray-800 text-gray-200 shadow sm:mt-0 sm:w-48 sm:pb-2 min-h-screen12">
        <Link
          icon="gear"
          to="/admin"
          className="mt-6 px-3 text-xl font-semibold flex items-center mb-2"
        >
          <Icon icon="gear" className="mr-3 h-4 w-4" />
          {app().t("Admin")}
        </Link>
        <Inserter match="*" source="/user/podcasterlist" />
      </Wrapper>
      <Wrapper className="flex-grow px-8 pt-2 pb-32 max-w-3xl">
        <Inserter match="/admin/program/*" source="/program/*" />
        <Inserter match="/admin/episode/*" source="/episode/*" />
        <Inserter
          match="/admin"
          source="/content/page/podcaster"
          className="-mt-32 -ml-20"
        />
      </Wrapper>
    </Inserter>
  );
};

export default PartPodcaster;
