import React, { useEffect, useRef } from "react";
import { Wrapper } from "nystem-components";

const AddCover = ({ children, addCover }) =>
  !addCover ? (
    children
  ) : (
    <Wrapper className="relative h-full w-full">
      <Wrapper className="absolute inset-0" />
      {children}
    </Wrapper>
  );

const MessageBusVimeoVideo = ({ model, view }) => {
  const ref = useRef();
  const id = view.mediaId;

  useEffect(() => {
    if (!ref.current) return;

    const post = (msg) =>
      ref.current.contentWindow.postMessage(JSON.stringify(msg), "*");

    let media = {};

    const update = (value) => {
      if (value.playing && !media.playing) {
        post({ method: "play" });
      } else if (!value.playing && media.playing) post({ method: "pause" });

      if (!value) return;

      if (
        value.currentTime &&
        Math.abs(media.currentTime - value.currentTime) > 1
      ) {
        post({ method: "setCurrentTime", value: value.currentTime });
      }
      media = value;
    };

    view.on("media", update);

    function onMessageReceived(event) {
      if (!/^https?:\/\/player.vimeo.com/.test(event.origin)) return false;
      const data =
        typeof event.data === "string" ? JSON.parse(event.data) : event.data;

      if (data.method === "addEventListener" && data.value === "playProgress") {
        clearInterval(playProgress);
        post({ method: "addEventListener", value: "finish" });
      }

      if (data.event === "playProgress") {
        const { seconds, duration } = data.data;
        view.event("media", { currentTime: seconds, duration });
      }
      if (data.event === "ready") {
        if (media.playing) post({ method: "play" });
        post({ method: "setCurrentTime", value: media.currentTime });
      }
    }

    if (window.addEventListener)
      window.addEventListener("message", onMessageReceived, false);
    else window.attachEvent("onmessage", onMessageReceived, false);

    const playProgress = setInterval(() => {
      post({ method: "addEventListener", value: "playProgress" });
    }, 100);

    return () => {
      window.removeEventListener("message", onMessageReceived);
      clearInterval(playProgress);
      view.off("media", update);
    };
  }, [model, view]);

  if (!id) return null;

  return (
    <AddCover addCover={model.disabled}>
      <Wrapper
        ref={ref}
        src={`https://player.vimeo.com/video/${id}?api=1&player_id=player1`}
        renderAs="iframe"
        className={model.className}
        allowFullScreen={true}
        frameBorder="0"
        allow="autoplay"
      />
    </AddCover>
  );
};
export default MessageBusVimeoVideo;
