import { useEffect } from "react";

const getClasses = ({ classNameByValue, field, view }) => {
  const testValue = view.getValue(field);
  return classNameByValue.reduce(
    (res, [key, value]) => (key === testValue ? [...res, value] : res),
    []
  );
};

const TinymceClassNameByValue = ({ path, view, model }) => {
  const { mapper } = model;
  let { className } = model;
  className = className instanceof Array ? className : [className];

  className = mapper.reduce(
    (res, value) => [...res, ...getClasses({ ...value, view })],
    className
  );

  useEffect(() => {
    setTimeout(() => {
      view.event(`tinymceInputClass${path}`, { className });
    }, 0);
  }, [view.value, className, view, path]);

  return null;
};

export default TinymceClassNameByValue;
