import { Wrapper } from "nystem-components";

const UrlimageView = ({ model, wrapper, value }) => {
  const className =
    model.className && !wrapper ? model.className.join(" ") : "";
  return (
    <Wrapper className={className} renderAs={model.renderAs || model.format}>
      {value || model.fallback}
    </Wrapper>
  );
};
export default UrlimageView;
