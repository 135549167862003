import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const StyleClassByEvent = ({ view, model, path }) => {
  const { className, classNameActive, item } = model;
  const [active, setActive] = useState();

  useEffect(() => {
    let active = false;
    const toggle = () => {
      active = !active;
      setActive(active);
    };

    view.on(model.event, toggle);
    return () => {
      view.off(model.event, toggle);
    };
  });

  return (
    <Wrapper className={[!active && className, active && classNameActive]}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default StyleClassByEvent;
