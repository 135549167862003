import React, { useEffect } from "react";
import {
  MessageBusMp3Player,
  MessageBusYoutubeVideo,
  MessageBusVimeoVideo,
} from "nystem-components";
import app from "nystem";

const testUrl = {
  youtube:
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  vimeo:
    /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/,
};

const TextMediaEmbedPreview = ({ model, value: mediaUrl, view }) => {
  useEffect(() => {
    let from;
    let to;
    const { from: defFrom = model.from || 0, to: defTo = model.to || 60 } =
      app().settings.episodePreviewDefault || {};

    const change = () => {
      from = view.getValue("preview.from") || defFrom;
      to = view.getValue("preview.to") || defTo;
    };
    change();
    view.on("change", change);

    const onMedia = (value) => {
      if (!value.duration) return;

      if (value.currentTime > to - from) {
        value.currentTime = 0;
        value.playing = false;
        value.isPlaying = undefined;
      }

      if (value.duration > to - from) value.duration = to - from;

      value.to = to;
      value.from = from;
    };

    view.on("media", 10, onMedia);
    return () => {
      view.off("media", onMedia);
      view.off("change", change);
    };
  }, [model, view]);

  if (!mediaUrl && view.value?.media?.ext)
    return (
      <MessageBusMp3Player
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  if (!view.value.media && (!mediaUrl || !mediaUrl.match)) return null;

  if (view.value.media || mediaUrl.match(/\.(mp3|m4a)/i))
    return (
      <MessageBusMp3Player
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  let [, id] = testUrl.youtube.exec(mediaUrl) || [];
  if (id)
    return (
      <MessageBusYoutubeVideo
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  [, id] = testUrl.vimeo.exec(mediaUrl) || [];
  if (id)
    return (
      <MessageBusVimeoVideo
        key={`${view.mediaId}${view.value._id}`}
        view={view}
        model={model}
      />
    );

  return null;
};

export default TextMediaEmbedPreview;
