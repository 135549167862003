import * as React from "react";
import { InputWrapper, Input, Wrapper } from "nystem-components";
import app from "nystem";

class UrlimageInput extends React.Component {
  constructor(props) {
    super(props);
    this.model = props.model;
    const state = {
      value: props.value,
    };
    ["clearVal", "handleChange", "valid"].forEach((func) => {
      this[func] = this[func].bind(this);
    });
    if (props.add && this.model.default)
      props.setValue(this.model.id, this.model.default);
    this.errormsg = this.model.text_mandatory
      ? this.model.text_mandatory
      : true;
    this.validated = false;
    this.state = state;
  }
  handleChange(value) {
    this.validated = true;
    this.setState({
      value: value,
      error: this.model.mandatory && !value ? this.errormsg : false,
    });
    this.props.setValue(this.model.id, value);
  }
  valid() {
    this.validated = true;
    if (this.model.mandatory && !this.state.value) {
      this.setState({
        error: this.errormsg,
      });
      return false;
    }
    if (this.state.error) delete this.state.error;
    return true;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const error = this.validated && this.model.mandatory && !nextProps.value;
      this.setState({
        value: nextProps.value,
        error: error ? this.errormsg : false,
      });
    }
  }
  clearVal() {
    const { model } = this.props;
    this.props.setValue(model.id);
  }
  render() {
    const self = this;
    const { model } = this.props;
    function clearButton() {
      if (!model.clearButton || !self.state.value) return;
      return (
        <Wrapper
          onClick={self.clearVal}
          className="fa fa-remove pointer-cursor form-control-feedback"
          aria-hidden="true"
        />
      );
    }
    if (model.clearButton) model.className.push("has-feedback");
    return (
      <InputWrapper model={this.model} error={this.state.error}>
        <Input
          placeholder={app().t(this.model.text)}
          className="form-control"
          value={this.state.value || ""}
          maxLength={this.model.length}
          onChange={this.handleChange}
          disabled={this.model.disabled}
          type="text"
          focus={this.props.focus}
        />
        {clearButton()}
      </InputWrapper>
    );
  }
}
export default UrlimageInput;
