import { ContentTypeRender, UseUser } from "nystem-components";

const SessionInsertToModel = ({ model, path, value }) => {
  const { map, item } = model;
  const user = UseUser();

  if (!user) return null;

  const insertVal = (val, from) => {
    if (!value) return val;

    const inserted = val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      const parts = p1.split(".");
      if (p1 === "from") return from;
      return parts[0] === "view" ? value[parts[1]] : user[p1];
    });
    if (inserted === "true") return true;
    if (inserted === "false" || inserted === "undefined") return false;
    return inserted;
  };

  return (
    <ContentTypeRender
      items={item.map((item) => ({
        ...item,
        ...map.reduce((result, [insert, to, fallback]) => {
          result[to] = insertVal(insert, user, item[to]) || fallback;
          return result;
        }, {}),
      }))}
      path={path}
    />
  );
};

export default SessionInsertToModel;
