import { Wrapper } from "nystem-components";
import app from "nystem";

const getValue = (value, path) =>
  path ? path.split(".").reduce((val, key) => val && val[key], value) : value;

const ViewTextInsertVal = ({ view, model, path }) => {
  const insertVal = (p1) => {
    if (p1 === "id") return view.id;

    if (p1.startsWith("params.")) return view.params[p1.replace("params.", "")];

    if (p1.startsWith("app.settings."))
      return getValue(app().settings, p1.replace("app.settings.", ""));

    if (!p1.startsWith("baseView."))
      return view.getValue(p1.replace("..", path));

    p1 = p1.replace("baseView.", "");
    return view.baseView.getValue(p1.replace("..", path));
  };

  return (
    <Wrapper className={model.className}>
      {insertVal(model.fieldPath) || model.fallback}
    </Wrapper>
  );
};

export default ViewTextInsertVal;
