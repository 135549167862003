import { useState } from "react";
import {
  InputWrapper,
  Input,
  UseValidator,
  Icon,
  Wrapper,
} from "nystem-components";
import validate from "./validate";

const Subvalue = ({ model, value, focus, field, setValue }) => {
  const parts = getParts(value);
  let at = parts[field];

  if (value === undefined) at = "";

  const [inpVal, setinpVal] = useState();

  const getVal = (value) => {
    if (value === undefined) return undefined;
    const val = value.replace(/[^0-9]/gim, "");
    if (val.length > 2) return val.substring(val.length - 2);
    return val;
  };

  if (at !== "" && at < 10) at = `0${at}`;

  return (
    <Input
      placeholder={field}
      className="w-14 mx-1 text-right"
      value={inpVal !== undefined ? inpVal : at}
      onChange={(value) => {
        setinpVal(getVal(value));
      }}
      type="text"
      focus={model.focus || focus}
      onBlur={() => {
        if (inpVal === undefined) return;

        setValue(parseInt(inpVal, 10));
        setTimeout(() => {
          setinpVal(undefined);
        }, 0);
      }}
      selectAllOnFocus={true}
    />
  );
};

const getParts = (value) => {
  const hour = Math.trunc(value / 3600) || 0;
  const min = Math.trunc((value - hour * 3600) / 60) || 0;
  const sec = value - hour * 3600 - min * 60 || 0;
  return { hour, min, sec };
};

const IntInputTime = ({ model, view, focus, setValue, value }) => {
  const [error, setValidated] = UseValidator({ view, validate, value, model });

  const setFieldValue = (field) => (val) => {
    const parts = getParts(value);
    parts[field] = val;
    const { hour = 0, min = 0, sec = 0 } = parts;

    if (val === undefined) {
      setValue(undefined);
      return;
    }
    setValue(hour * 3600 + min * 60 + sec);
    setValidated();
  };

  const ClearButton = () => (
    <Icon
      onClick={() => setValue(undefined)}
      className="mx-2 mt-1 h-6 w-6 rounded-md bg-red-600 p-1 text-white shadow-xl hover:bg-red-700 fill-current"
      icon="xmark"
      aria-hidden="true"
    />
  );

  return (
    <InputWrapper model={model} error={error}>
      <Wrapper className={model.classNameInput}>
        <Subvalue
          model={model}
          value={value}
          field="hour"
          setValue={setFieldValue("hour")}
          focus={focus}
        />
        :
        <Subvalue
          model={model}
          value={value}
          field="min"
          setValue={setFieldValue("min")}
        />
        :
        <Subvalue
          model={model}
          value={value}
          field="sec"
          setValue={setFieldValue("sec")}
        />{" "}
        {value !== undefined ? <ClearButton /> : null}
      </Wrapper>
    </InputWrapper>
  );
};
export default IntInputTime;
