import React, { useEffect, useRef } from "react";
import app from "nystem";
/*
const init = () => {
  let metadata = false;

  const setMedia = ({ image, title, album, artist }) => {
    const sizes = [
      "96x96",
      "128x128",
      "192x192",
      "256x256",
      "384x384",
      "512x512",
    ];
    const artwork = sizes.map((sizes) => ({
      src: `/image/${sizes}/${image}.jpeg`,
      sizes,
      type: "image/jpeg",
    }));

    if (!metadata)
      metadata = new window.MediaMetadata({
        title,
        artist,
        album,
        artwork,
      });
  };
  app().on("media", -1000, setMedia);

  const setSession = () => {
    if (!metadata) return;
    if (!navigator.mediaSession.metadata)
      navigator.mediaSession.metadata = new window.MediaMetadata(metadata);

    console.log("dooon", navigator.mediaSession.metadata);
  };
  app().on("media", -1000, setSession);

  const play = () => {
    console.log("play");
    app().event("media", { playing: true });
  };
  const pause = () => {
    console.log("pause");
    app().event("media", { playing: false, isPlaying: false });
  };
  const stop = () => {
    app().event("media", { playing: false, isPlaying: false, currentTime: 0 });
  };
  const seekbackward = () => {
    const audio = document.querySelector("audio");
    app().event("media", { currentTime: audio.currentTime - 10 });
  };
  const seekforward = () => {
    const audio = document.querySelector("audio");
    app().event("media", { currentTime: audio.currentTime + 10 });
  };
  const seekto = (e) => {
    console.log(e);
    // view.event("media", { currentTime: audio.currentTime + 10 });
  };

  navigator.mediaSession.setActionHandler("play", play);
  navigator.mediaSession.setActionHandler("pause", pause);
  navigator.mediaSession.setActionHandler("stop", stop);
  navigator.mediaSession.setActionHandler("seekbackward", seekbackward);
  navigator.mediaSession.setActionHandler("seekforward", seekforward);
  navigator.mediaSession.setActionHandler("seekto", seekto);
  navigator.mediaSession.playbackState = "playing";
};
setTimeout(init, 200);
*/

const MediaGlobalAudio = () => {
  const ref = useRef();
  const source = useRef();
  const onChange = () => {};

  useEffect(() => {
    const ended = () => {
      console.log("audio ended");
      onChange({
        currentTime: 0,
        playing: false,
        isPlaying: false,
      });
    };
    ref.current.addEventListener("ended", ended);
    /*
    const paused = () => {
      setTimeout(() => {
        onChange({
          playing: false,
          isPlaying: false,
        });
      }, 100);
    };
    ref.current.addEventListener("pause", paused);
*/
    const play = () => {
      app().event("media", { playing: true });
    };
    ref.current.addEventListener("play", play);

    const loaded = () => {
      console.log("loaded");
      onChange({ duration: parseInt(ref.current.duration, 10) });
    };
    ref.current.addEventListener("loadeddata", loaded);
    /*
    const error = (e) => {
      console.log("error", e.target.src);
      app().event("media", { playing: true });
    };
    source.current.addEventListener("error", error);
    let updateTick = setInterval(() => {
      if (playing)
        onChange({
          currentTime: ref.current.currentTime,
          duration: ref.current.duration,
          isPlaying: ref.current.readyState === 4,
        });
    }, updateInterval || 1000);
*/

    return () => {};
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio ref={ref}>
      <source ref={source} />
      Your browser does not support the audio element.
    </audio>
  );
};
export default MediaGlobalAudio;
