import { useEffect, useState } from "react";

const MessageBusView = (view, event, field) => {
  const [value, setVal] = useState();

  useEffect(() => {
    const update = (value) => {
      setVal(value[field]);
    };

    view.on(event, update);
    view.event(event);

    return () => {
      view.off(event, update);
    };
  }, [event, field, view]);

  return value;
};

export default MessageBusView;
