import { useEffect } from "react";

const StyleElementClass = ({ model }) => {
  useEffect(() => {
    const { className, element } = model;
    const elements = document.querySelectorAll(element);

    elements.forEach((element) => element.classList.add(className.join(" ")));
    return () => {
      elements.forEach((element) =>
        element.classList.remove(className.join(" "))
      );
    };
  }, [model]);
  return null;
};
export default StyleElementClass;
