import React, { useEffect, useRef } from "react";
import { Wrapper } from "nystem-components";

const AddCover = ({ children, addCover }) =>
  !addCover ? (
    children
  ) : (
    <Wrapper className="relative h-full w-full">
      <Wrapper className="absolute inset-0" />
      {children}
    </Wrapper>
  );

const MessageBusYoutubeVideo = ({ model, view }) => {
  const ref = useRef();
  const id = view.mediaId;

  useEffect(() => {
    if (!ref.current) return;

    const post = (msg) =>
      ref.current?.contentWindow?.postMessage(JSON.stringify(msg), "*");

    let media = {};

    const onMedia = (value) => {
      if (media) {
        if (value.playing && !media.playing) {
          post({ event: "command", func: "playVideo" });
        } else if (!value.playing && media.playing)
          post({ event: "command", func: "pauseVideo" });
      }

      if (
        value.currentTime &&
        Math.abs(media.currentTime - value.currentTime) > 1
      ) {
        post({
          event: "command",
          func: "seekTo",
          args: [value.currentTime, true],
        });
      }
      media = value;
    };

    view.on("media", onMedia);

    function onMessageReceived(event) {
      if (!/^https?:\/\/www.youtube.com/.test(event.origin)) return false;
      const data = JSON.parse(event.data);

      if (data.method === "addEventListener" && data.value === "playProgress") {
        clearInterval(playProgress);
        post({ method: "addEventListener", value: "finish" });
      }
      /*
        player.getPlayerState():Number
        Returns the state of the player. Possible values are:
        -1 – unstarted
        0 – ended
        1 – playing
        2 – paused
        3 – buffering
        5 – video cued
        */

      if (data.event === "infoDelivery") {
        const { currentTime, playerState } = data.info;
        if (playerState === 1 && !media.isPlaying)
          view.event("media", { currentTime, playing: true, isPlaying: true });
        else if (playerState === 2 && !media.isPlaying)
          view.event("media", {
            currentTime,
            playing: false,
            isPlaying: undefined,
          });
        else if (currentTime) {
          if (Math.abs(media.currentTime - data.currentTime) < 1) return;
          view.event("media", { currentTime });
        }
      }
      if (data.event === "onReady" && media.currentTime) {
        post({
          event: "command",
          func: "seekTo",
          args: [media.currentTime, false],
        });
      }
      if (data.event === "initialDelivery") {
        const { duration } = data.info;

        view.event("media", { duration });
      }
    }

    if (window.addEventListener)
      window.addEventListener("message", onMessageReceived, false);
    else window.attachEvent("onmessage", onMessageReceived, false);

    const playProgress = setInterval(() => {
      post({ event: "listening", id: 1, channel: "widget" });
    }, 100);

    return () => {
      view.off("media", onMedia);
      window.removeEventListener("message", onMessageReceived);
      clearInterval(playProgress);
    };
  }, [model, view]);

  if (!id) return null;

  return (
    <AddCover addCover={model.disabled}>
      <Wrapper
        ref={ref}
        src={`https://www.youtube.com/embed/${id}?rel=0&amp;showinfo=0&html5=1&enablejsapi=1`}
        renderAs="iframe"
        className={model.className}
        allowFullScreen={true}
        frameBorder="0"
        allow="autoplay"
      />
    </AddCover>
  );
};
export default MessageBusYoutubeVideo;
