import { Wrapper } from "nystem-components";
import app from "nystem";

const StyleInsertSettings = ({ model, ...rest }) => {
  const { val, className } = model || rest;
  const __html = app().settings[val];
  if (!__html) return null;

  return <Wrapper className={className} dangerouslySetInnerHTML={{ __html }} />;
};
export default StyleInsertSettings;
