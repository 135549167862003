/* eslint-disable guard-for-in */
import React, { useEffect, useState, useContext } from "react";
import app from "nystem";
import { DatabaseSearchContext, Wrapper, ExportView } from "nystem-components";

const getValue = (value) => (path) =>
  path ? path.split(".").reduce((val, key) => val && val[key], value) : value;

const pause = (delay = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

const ExportExport = ({ model, view }) => {
  const { search } = useContext(DatabaseSearchContext);
  const [progressText, setProgressText] = useState("");

  const { text, item, className, renderAs } = model;
  const { contentType } = view;

  useEffect(() => {
    let aborted = false;
    const count = 200000;

    let addCount = 0;
    let finishing = false;

    const exportStart = async () => {
      aborted = false;

      const baseSearch = {
        ...search,
        data: undefined,
        count,
        reverse: !search.reverse,
      };
      let result = await app().database[contentType].search(baseSearch);

      const labelView = { getValue: getValue(result.data[0]) };
      const items = await Promise.all(
        item.map((model) => ExportView({ view: labelView, model, label: true }))
      );
      await view.event("exportLabels", {
        data: items.flat(),
        filter: search.filter,
      });

      let lastLog = 0;
      for (let position = 0; position < result.searchTotal; position += count) {
        if (aborted) break;

        for (const i in result.data) {
          if (aborted) break;

          const time = Date.now();
          if (time - lastLog > 100) {
            lastLog = time;
            setProgressText(
              `${text} ${Math.round(
                ((position + parseInt(i, 10)) / result.searchTotal) * 100
              )}% ${position + parseInt(i, 10) + addCount}`
            );
            await pause();
          }

          await view.event("exportAddRow", { data: result.data[i] });
        }

        result = await app().database[contentType].search({
          ...baseSearch,
          position,
        });
      }

      finishing = true;
      if (!aborted) {
        setProgressText("Finishing export");

        const { blob, fileName } = await view.event("exportFinish");
        setProgressText("Export done");
        if (!blob) return;

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      } else setProgressText("");
    };
    view.on("exportStart", -100, exportStart);

    const exportCount = ({ count }) => {
      addCount += count;
      if (finishing)
        setProgressText(
          `Finishing export${addCount ? ` ${addCount} left` : ""}`
        );
    };
    view.on("exportCount", exportCount);

    const exportAddRow = async ({ data, ...rest }) => {
      const view = { getValue: getValue(data) };
      const items = item.map((model) => ExportView({ view, model }));

      return { data: (await Promise.all(items)).flat(), ...rest };
    };
    view.on("exportAddRow", exportAddRow);

    const exportFinish = ({ aborted: abortedIn }) => {
      aborted = abortedIn;
    };
    view.on("exportFinish", exportFinish);

    return () => {
      view.off("exportStart", exportStart);
      view.off("exportAddRow", exportAddRow);
      view.on("exportFinish", exportFinish);
    };
  }, [contentType, item, model, search, text, view]);

  if (!progressText) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      {progressText}
    </Wrapper>
  );
};

export default ExportExport;
